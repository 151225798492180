import React, { useState, useEffect, useContext } from 'react';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { ctx } from '../../../AppWrapper';
import { Badge } from 'primereact/badge';
// import './Sales.scss'

const TeamStrengthList = (props) => {
    const [selectedUser, setSelectedusers] = useState([]);
    const [chartData, setChartData] = useState([]);
    const ctxState = useContext(ctx);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                max: 9,
                min: 0,
                ticks: {
                    stepSize: 1
                },
                pointLabels: {
                    color: '#495057',
                },
                grid: {
                    color: '#ebedef',
                },
                angleLines: {
                    color: '#ebedef'
                }
            },

        }
    });

    useEffect(() => {
        if (props.chartDialog) {
            customDataForChart();
        }

    }, [props])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedusers([]);
        props.selectedRow(null);
    }, [props.dataTableList])// eslint-disable-line react-hooks/exhaustive-deps



    const customDataForChart = () => {
        const labels = [];
        props?.allCol?.forEach((col) => {
            labels.push(col.header_name);
        });

        const dataset = [];
        let chartDataObj = {};
        let userName = "";

        selectedUser.forEach((user, index) => {

            let data = [];
            let randomColor = '';
            userName = user.first_name + " " + user.last_name;
            const keys = Object.keys(user);

            for (let j = 0; j < keys.length; j++) {
                if (typeof user[`${keys[j]}`] === 'number' && keys[j] !== 'user_id') {
                    data.push(user[`${keys[j]}`]);
                    randomColor = '#' + Math.floor(Math.random() * 16777215).toString(17);
                }
            }

            dataset.push({ label: userName, data: data, borderColor: randomColor, pointHoverBackgroundColor: '#fff', });
        });

        chartDataObj = ({ userName: userName, labels: labels, datasets: dataset });
        setChartData(chartDataObj);
    }

    const onSlection = (e, data) => {
        data.isSelected = e.target.checked;
        if (data.isSelected) {
            props.dataTableList.forEach((listVal) => {
                if (listVal.user_id === data.user_id) {
                    setSelectedusers([...selectedUser, listVal]);
                    props.selectedRow([...selectedUser, listVal]);
                }
            });
        } else {
            onUnselect(data);
        }
    }

    const onUnselect = (data) => {
        let selectedRows = [...selectedUser];
        if (selectedRows.length > 0) {
            const index = selectedRows.findIndex(val => val.user_id === data.user_id);
            selectedRows.splice(index, 1);
            setSelectedusers([...selectedRows]);
            if (!selectedRows.length) {
                props.selectedRow(null);
            } else {
                props.selectedRow([...selectedRows]);
            }
        }
    }


    const statusBodyTemplate = (rowData, column) => {
        const field = column.field;
        let clr = '';
        ctxState.pointColors?.colors_data_for_9?.forEach((val) => {
            if (val.points_list.includes(rowData[`${field}`])) {
                clr = val.color;
            }
        });
        return <Badge value={rowData[`${field}`]} size="large" severity="success" style={{ background: `${clr}`, color: 'white' }}  ></Badge>
    }

    const firstNameBody = (rowData, col) => {
        return (
            <>
                <label className="custom-checkbox" tab-index="0" aria-label="Checkbox Label">
                    <input type="checkbox" defaultChecked={rowData.isSelected} onChange={e => onSlection(e, rowData, !rowData.isSelected)} />
                    <span className="checkmark"></span>
                </label>
                <span className="image-text">{rowData.first_name}</span>
            </>
        )
    }

    return (
        <>
            <div className="card">

                <DataTable value={props.dataTableList} scrollable responsiveLayout="scroll" scrollHeight="500px" scrollDirection="both" showGridlines className="mt-3">
                    <Column field="first_name"  header="First Name" style={{ flexGrow: 1, flexBasis: '150px' }} sortable body={firstNameBody}></Column>
                    <Column field="last_name"  header="Last Name" style={{ flexGrow: 1, flexBasis: '150px' }} sortable></Column>
                    {props?.allCol && props?.allCol.map((column, index) => {
                        return <Column key={index} field={column.column_name} header={column.header_name}  style={{ flexGrow: 1, flexBasis: '150px' }} body={statusBodyTemplate} sortable></Column>
                    })}
                </DataTable>
            </div>

            <Dialog visible={props.chartDialog} style={{ width: '55%' }} header=" Team Strength " modal className="p-fluid" onHide={() => props.onChartHide()}>
                <div className="card flex justify-content-center">
                    <Chart type="radar" data={chartData} options={lightOptions} style={{ position: 'relative', width: '70%' }} />
                </div>
            </Dialog>

        </>);
}

export default TeamStrengthList;