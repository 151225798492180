import { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Chips } from 'primereact/chips';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import DataService from '../../service/AssistantToolApi-service';
import { classNames } from 'primereact/utils';
import { companyContext } from './Corporaters';
import { MultiSelect } from 'primereact/multiselect';
import "./Corporates.scss";

const PoolDetails = (props) => {
    let emptyPools = {
        deadline: "",
        industry: "",
        description: "",
        qualify_email: 'null',
        recruitment_process: "null",
        reject_email: "null",
        send_assessment_email: "null",
        target_profile: {},
        title: "",
        is_closed: false,
        company_id: 0,
        assessments: [],
        email_list: [],
        hash: ''
    }

    const assessmentsOpt = [
        { id: 31, name: 'Personality & Behaviour' },
        { id: 32, name: 'Values & Motivation' },
        { id: 33, name: 'Verbal Reasoning' },
        { id: 34, name: 'Numerical Reasoning' },
        { id: 37, name: 'Logical Reasoning' },
        { id: 38, name: 'English' },

    ]
    const company = useContext(companyContext);
    const [pool, setPool] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [businessType] = useState(company.industryList);
    const [targetProfiles] = useState(company.targetProfile);
    const api = new DataService();

    useEffect(() => {
        if (props.pool) {
            setPropsPoolData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    const changeHandler = (e, name) => {
        let val = (e.target && e.target.value) || '';
        if (name === "department" || name === "target_profile") {
            val = e.target?.value
        }
        let _pool = { ...pool };
        _pool[`${name}`] = val;
        setPool(_pool);
    }

    const hasNullProp = (obj) => {
        const newObj = Object.assign(emptyPools, obj)
        for (const key of Object.keys(newObj)) {
            if (newObj[key] === '' && key !== 'hash') {
                return true
            }
        }
        return false
    }

    const setPropsPoolData = () => {
        let editPool = emptyPools
        editPool.target_profile.id = props.pool?.target_profile?.id;
        editPool.target_profile.title = props.pool?.target_profile?.name;
        editPool.deadline = new Date(props.pool.deadline);
        editPool.industry = props.pool.industry;
        editPool.description = props.pool.description;
        editPool.qualify_email = 'null';
        editPool.recruitment_process = "null";
        editPool.reject_email = "null";
        editPool.send_assessment_email = "null";
        editPool.title = props.pool.title;
        editPool.is_closed = props.pool.is_closed;
        editPool.company_id = props.pool.company_id;
        editPool.assessments = setAssesmentsforDropdown(props.pool?.assessments);
        editPool.email_list = props.pool.email_list.split(',') || [];
        editPool.hash = props.pool.hash || '';
        setPool(editPool);
    }

    const setAssesmentsforDropdown = (arr) => {
        let assessmentArr = [];
        if (arr.length) {
            arr.forEach((val) => {
                assessmentsOpt.forEach((asses) => {
                    if (val === asses.id) {
                        assessmentArr.push(asses);
                    }
                })
            });
        }
        return assessmentArr;
    }

    const createPayLoad = (obj) => {
        let payload = {};
        payload.title = obj.title;
        payload.description = obj.description;
        payload.recruitment_process = [];
        payload.send_assessment_email = '';
        payload.qualify_email = '';
        payload.reject_email = '';
        payload.industry_id = obj?.industry.id + "";
        payload.target_profile_id = obj?.target_profile.id + "";
        payload.is_closed = obj.is_closed;
        payload.email_list = obj?.email_list?.toString() || "";
        payload.company_id = company?.id + '';
        payload.assessments = obj.assessments.length ? obj?.assessments?.map(val => val.id) : [];
        payload.deadline = obj.deadline.toString();
        let mydate = new Date(payload.deadline);
        payload.deadline = mydate.getFullYear() + "/" + (mydate.getMonth() + 1) + "/" + mydate.getDate() + " 00:00:00";
        return payload;
    }

    const submitResponse = () => {

        setSubmitted(true);
        setLoading(true);
        if (hasNullProp(pool) === true || !pool.assessments?.length || (pool.is_closed && !pool.email_list?.length)) {
            setLoading(false);
            return
        }
        let payload = createPayLoad(pool);
        if (pool.hash && pool.hash !== '') {
            payload.hash = pool.hash || "";
            api.editCompanyPool(payload)
                .then((res) => {
                    api.addEvent(4);
                    setLoading(false);
                    props.close();
                })
                .catch((error) => {
                    setLoading(false);
                })
        } else {
            api.addCompanyPool(payload)
                .then((res) => {
                    api.addEvent(3);
                    setLoading(false);
                    props.close();
                })
                .catch((error) => {
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <div className="flex flex-column">
                <div className="field col-12 openPool">
                    <label htmlFor="is_closed" style={{ margin: 0 }}> <h5>Close Pool</h5></label> &nbsp;&nbsp;
                    <InputSwitch checked={pool.is_closed} onChange={(e) => changeHandler(e, 'is_closed')} />
                </div>
                <div className="field col-12 ">
                    <label htmlFor="title" style={{ margin: 0 }}> <h5>New Position Title</h5></label>
                    <InputText id="title" type="text" autoFocus placeholder="Start typing..." value={pool.title} onChange={(e) => changeHandler(e, 'title')} required className={classNames({ 'p-invalid': submitted && !pool.title })} />
                    {submitted && !pool.title && <small style={{ color: 'red' }}>Position title is required.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="department" style={{ margin: 0 }}> <h5> Industry/Field</h5>  </label>
                    <Dropdown optionLabel="name" options={businessType} placeholder="Select department" value={pool.industry} onChange={(e) => changeHandler(e, 'industry')} required className={classNames({ 'p-invalid': submitted && !pool.industry })} />
                    {submitted && !pool.industry && <small style={{ color: 'red' }}>Please select a industry.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="target_profile" style={{ margin: 0 }}><h5>Target Profile <sup> &#x3F;</sup></h5></label>
                    <Dropdown optionLabel="title" options={targetProfiles} placeholder="Select target profile" value={pool.target_profile} onChange={(e) => changeHandler(e, 'target_profile')} required className={classNames({ 'p-invalid': submitted && !pool.target_profile })} />
                    {submitted && !pool.target_profile && <small style={{ color: 'red' }}>Please select a profile.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="description" style={{ margin: 0 }}><h5> Assessments</h5> </label>
                    <MultiSelect value={pool.assessments} options={assessmentsOpt} onChange={(e) => changeHandler(e, 'assessments')} optionLabel="name" placeholder="Select a value" display="chip" className={classNames({ 'p-invalid': submitted && !pool.assessments?.length })} />
                    {submitted && !pool.assessments?.length && <small style={{ color: 'red' }}>Assessments are required.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="deadline" style={{ margin: 0 }}> <h5> Deadline</h5>  </label>
                    <Calendar id="deadline" dateFormat="yy-mm-dd" placeholder="Select a date" value={pool.deadline} onChange={(e) => changeHandler(e, 'deadline')} required className={classNames({ 'p-invalid': submitted && !pool.deadline })} />
                    {submitted && !pool.deadline && <small style={{ color: 'red' }}>Please select a date.</small>}
                </div>
                {pool.is_closed ? <div className="field col-12 ">
                    <label htmlFor="qualify_email" style={{ margin: 0 }}> <h5>Qualify Emails</h5></label>
                    <Chips value={pool.email_list} placeholder="Qualify Emails" onChange={(e) => changeHandler(e, 'email_list')} separator="," required className={classNames({ 'p-invalid': submitted && !pool.email_list?.length })} />
                    {submitted && !pool.email_list?.length && pool.is_closed && <small style={{ color: 'red' }}>Qualify Emails are required.</small>}
                </div> :
                    ''
                }
                <div className="field col-12 ">
                    <label htmlFor="description" style={{ margin: 0 }}><h5> Description</h5> </label>
                    <InputTextarea id="description" type="text" placeholder="Start typing..." autoResize rows="3" cols="30" value={pool.description || ''} onChange={(e) => changeHandler(e, 'description')} required className={classNames({ 'p-invalid': submitted && !pool.description })} />
                    {submitted && !pool.description && <small style={{ color: 'red' }}>Description is required.</small>}
                </div>



            </div>


            <div className="flex justify-content-end ">
                <Button loading={loading} label="Save" icon="pi pi-check" type="button" onClick={submitResponse} ></Button>
            </div>
        </>
    );
}

export default PoolDetails;
