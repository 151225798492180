import React, { useState } from 'react';
import DataService from '../../../service/AssistantToolApi-service';
import { Button } from 'primereact/button';
import SalesListView from './TeamStrengthList';
import ReportFilters from '../ReportFilters'

const TeamStrengthReport = () => {

    const api = new DataService();
    const [loader, setLoader] = useState(false);
    const [salesList, setSalesList] = useState([]);
    const [dataTableList, setDataTableList] = useState([]);
    const [chartDialog, setChartDialog] = useState(false);
    const [showChartBtn, setShowChartBtn] = useState(false);
    const [selectedFilterCol, setSelectedFilterCol] = useState('');
    const [allCol, setAllCol] = useState([]);


    const getTeamStrengthList = (payload) => {
        setSalesList([]);
        setDataTableList([])
        setLoader(true);
        api.getTeamStrengthReport(payload)
            .then((res) => {
                setLoader(false);
                const col = res.data.data.group_list;
                let userData = res.data.data.user_data;
                userData = userData.map(val => ({ ...val, isSelected: false }));
                setDataTableList(userData);
                setAllCol(col);
            })
            .catch((error) => { });
    }

    const selectedRow = (data) => {
        if (data) {
            setShowChartBtn(true);
        } else {
            setShowChartBtn(false);
        }
    }

    const onChartHide = () => {
        setChartDialog(false);
        setSelectedFilterCol(null);
    }

    const enableLoader = (e) => {
        setLoader(e);
    }
    
    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between" style={{ marginBottom: '1rem' }}>

                        <div className="flex">
                            <h5 className="mr-2">Team Strength</h5>
                            <ReportFilters callReportsData={getTeamStrengthList} enableLoader={enableLoader} />
                            {loader ? <div> <i className="pi pi-spin pi-spinner" ></i></div> : ''}
                        </div>

                        <div>
                            {showChartBtn ? <Button type="button" icon="pi pi-th-large" className="p-button-success mr-2" onClick={(e) => setChartDialog(true)} /> : ''}
                        </div>

                    </div>

                    <SalesListView salesList={salesList} dataTableList={dataTableList} chartDialog={chartDialog} selectedCol={selectedFilterCol} selectedRow={selectedRow} onChartHide={onChartHide} allCol={allCol} />

                </div>
            </div>


        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TeamStrengthReport, comparisonFn);