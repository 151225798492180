import React, { useState, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import DataService from '../../../service/AssistantToolApi-service';
import HelperService from '../../../service/CommonHelperService';
import { Button } from 'primereact/button';
import SalesListView from './SalesListView';
import { OverlayPanel } from 'primereact/overlaypanel';
import ReportFilters from '../ReportFilters'
const SalesReport = () => {

    const api = new DataService();
    const helperService = new HelperService();
    const [loader, setLoader] = useState(false);
    const [salesList, setSalesList] = useState([]);
    const [dataTableList, setDataTableList] = useState([]);
    const [chartDialog, setChartDialog] = useState(false);
    const [showChartBtn, setShowChartBtn] = useState(false);
    const [selectedFilterCol, setSelectedFilterCol] = useState('');
    const [allCol, setAllCol] = useState([]);
    const [colList, setcolList] = useState([]);
    const [userData, setUserData] = useState([]);
    const chartPannel = useRef();


    const getSalesList = (id, hash) => {
        setSalesList([]);
        setDataTableList([])
        setLoader(true);
        let dataTableData = [];
        let colList = [];
        api.getSalesReport(id, hash)
            .then((res) => {
                const col = res.data.data.group_list;
                const userData = res.data.data.user_data;
                // res = res.data.data;
                userData.forEach((val) => {
                    const valKeys = Object.keys(val);
                    valKeys.forEach((key, i, arr) => {
                        if (typeof val[`${key}`] === 'object' && val[`${key}`]) {
                            val[`${key}`]['isSelected'] = false;
                            dataTableData.push(val[`${key}`]);
                        }
                    });
                });
                col.forEach((val) => {
                    colList.push({ id: val.group_name, value: val.group_name });
                });
                colList.push({ id: 'comp&attitude', value: 'Competency & Attitude' });
                setcolList(colList);
                setUserData(res.data.data.user_data)
                setDataTableList(dataTableData)
                setSalesList(userData);
                setAllCol(col);
                setLoader(false);
            })
            .catch((error) => { });
    }




    const onColChange = (e) => {
        let val = e.target.value || e.value;
        setSelectedFilterCol(val);
        setChartDialog(true);
        chartPannel.current.hide();
    }

    const selectedRow = (data) => {
        if (data) {
            if (data.length > 1) {
                const index = colList.findIndex(val => val.id === 'comp&attitude');
                if (index !== -1) {
                    index && colList.splice(index, 1);
                    setcolList([...colList]);
                }
            } else {
                const lastEle = colList.at(-1);
                if (lastEle.id !== 'comp&attitude') {
                    colList.push({ id: 'comp&attitude', value: 'Competency & Attitude' });
                    setcolList(colList);
                }
            }
            setShowChartBtn(true);
        } else {
            setShowChartBtn(false);
        }
    }

    const onChartHide = () => {
        setChartDialog(false);
        setSelectedFilterCol(null);
    }

    const exportToCSV = () => {
        helperService.exportExcel(dataTableList, 'Sales')
    }

    const enableLoader = (e) => {
        setLoader(e);
    }

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between" style={{ marginBottom: '1rem' }}>
                        <div className="flex">
                            <h5 className="mr-2">Sales Reports</h5>
                            <ReportFilters callReportsData={getSalesList} enableLoader={enableLoader} />
                            {loader ? <div> <i className="pi pi-spin pi-spinner" ></i></div> : ''}
                        </div>
                        <div>
                            {showChartBtn ? <Button type="button" icon="pi pi-th-large" className="p-button-success mr-2" onClick={(e) => chartPannel.current.toggle(e)} /> : ''}
                            {dataTableList.length ? <Button type="button" icon="pi pi-file-excel" className="p-button-success mr-2" onClick={() => exportToCSV()} /> : ''}
                        </div>
                    </div>

                    <SalesListView salesList={salesList} dataTableList={dataTableList} chartDialog={chartDialog} userData={userData} selectedCol={selectedFilterCol} selectedRow={selectedRow} onChartHide={onChartHide} allCol={allCol} />

                    <OverlayPanel ref={chartPannel} style={{ width: '250px' }} className="overlaypanel-demo">
                        <Dropdown optionLabel="value" value={selectedFilterCol} className="dropdowns" onChange={(e) => onColChange(e)} options={colList} placeholder="Select a column" style={{ width: '100%' }} />
                    </OverlayPanel>
                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SalesReport, comparisonFn);