import React, { useState, useEffect, useRef } from 'react';
import DataService from '../../service/AssistantToolApi-service';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import './Dashboard.scss'
const DashboardCorporares = () => {
    const api = new DataService();
    const [CompanyList, setCompanyList] = useState(null);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    useEffect(() => {
        if (!CompanyList) {
            getCompanyList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCompanyList = () => {
        setLoader(true);
        api.getCompanyList().then((res) => {
            setCompanyList(res.data.data);
            setLoader(false);
        }).catch((error) => { })
    }

    const markActiveInActive = (e, company) => {
        setLoader(true);
        api.makeCompanyActiveInactive(company.id, e.value).then((res) => {
            if (e.value) {
                api.addEvent(5);
            } else {
                api.addEvent(6);
            }
            let updateVal = CompanyList.map(val => {
                if (val.id === company.id) {
                    val.active = e.value;
                }
                return val;
            });
            setCompanyList([...updateVal]);
            setLoader(false);
            e.value === true ? toast.current.show({ severity: 'success', summary: 'Successful', detail: `${company.name} marked as active`, life: 3000 }) :
                toast.current.show({ severity: 'success', summary: 'Successful', detail: `${company.name} marked as in-active`, life: 3000 });
        }).catch((error) => {
            setLoader(false);
            const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
            toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
        })
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-title flex justify-content-between col-12">
                            <h6>Corporates List</h6>
                            {loader ? <i className="pi pi-spin pi-spinner" ></i> : <i className="pi pi-sync pointer" onClick={() => getCompanyList()} ></i>}
                        </div>
                    </div>
                    <div className='corporates-list' >
                        {CompanyList?.map((company, index) => {

                            return (
                                <div key={company.id} className="card bg-lightGray flex pointer" style={{ overflow: "auto" }} >
                                    <span className="col-6" style={{ padding: '0' }}>
                                        <h5>{company.name}</h5>
                                        <small>{company.industry.name}</small>
                                    </span>
                                    <span className="col-4" style={{ padding: '0' }}>
                                        <p>{company.employee_count}</p>
                                    </span>
                                    <span className="col-2" style={{ padding: '0' }}>
                                        <InputSwitch checked={company.active} onChange={(e) => markActiveInActive(e, company)} />
                                    </span>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </>
    );
}

export default DashboardCorporares;
