import React, { useState, useEffect, useContext, memo } from 'react';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { ctx } from '../../../AppWrapper';
import { Badge } from 'primereact/badge';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import HelperService from '../../../service/CommonHelperService';
import './Sales.scss'

const SalesListView = (props) => {
    const helperService = new HelperService();
    const globalFilterOptions = ['All', 'Competency', 'Attitude'];
    const [globalFilter, setGlobalFilters] = useState('All');
    const [selectedUser, setSelectedusers] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [filters, setFilters] = useState([]);
    const [tabledata, setTableData] = useState([]);
    const [clearBtn, setClearBtn] = useState(false);
    const ctxState = useContext(ctx);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                max: 5,
                min: 0,
                ticks: {
                    stepSize: 1
                },
                pointLabels: {
                    color: '#495057',
                },
                grid: {
                    color: '#ebedef',
                },
                angleLines: {
                    color: '#ebedef'
                }
            },

        }
    });

    useEffect(() => {
        if (props.chartDialog) {
            customDataForChart();
        }
        if (!filters.length && props?.allCol[0]?.columns_list) { setIntialFilters() }
    }, [props.chartDialog, props.allCol])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedusers([]);
        props.selectedRow(null);
        setTableData(props.dataTableList)
    }, [props.dataTableList])// eslint-disable-line react-hooks/exhaustive-deps

    const setIntialFilters = () => {
        const allCol = props?.allCol[0]?.columns_list;
        let obj = {}
        allCol?.forEach((col) => {
            obj[`${col.column_name}`] = { constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
        });
        setFilters(obj);
    }

    const customDataForChart = () => {
        const labels = [];
        const allCol = props?.allCol[0]?.columns_list;
        allCol?.forEach((col) => {
            labels.push(col.header_name);
        });
        const dataset = [];
        let chartDataObj = {};
        let userName = "";
        if (props?.selectedCol.toLowerCase() === 'competency & attitude') {
            selectedUser.forEach((user, index) => {
                userName = user.user_name
                const keys = Object.keys(user);
                keys.forEach((key, i) => {
                    let nameLabel = '';
                    let randomColor = '';
                    let data = [];
                    if (typeof user[`${key}`] === 'object' && user[`${key}`]) {
                        nameLabel = key.toUpperCase();
                        const colKeys = Object.keys(user[`${key}`]);
                        for (let j = 0; j < colKeys.length; j++) {
                            if (typeof user[`${key}`][`${colKeys[j]}`] === 'number' && colKeys[j] !== 'user_id') {
                                data.push(user[`${key}`][`${colKeys[j]}`]);
                            }
                        }
                        randomColor = '#' + Math.floor(Math.random() * 16777215).toString(17);
                        dataset.push({ label: nameLabel, data: data, borderColor: randomColor, pointHoverBackgroundColor: '#fff', });
                    }
                });

            });
        } else {
            selectedUser.forEach((user, index) => {
                let data = [];
                let randomColor = '';
                const nameLabel = user.user_name;
                const keys = Object.keys(user);

                keys.forEach((key, i) => {
                    if (typeof user[`${key}`] === 'object' && key === props?.selectedCol.toLowerCase()) {
                        const colKeys = Object.keys(user[`${key}`]);
                        for (let j = 0; j < colKeys.length; j++) {
                            if (typeof user[`${key}`][`${colKeys[j]}`] === 'number' && colKeys[j] !== 'user_id') {
                                data.push(user[`${key}`][`${colKeys[j]}`]);
                            }
                        }
                    }
                });
                randomColor = '#' + Math.floor(Math.random() * 16777215).toString(17);
                dataset.push({ label: nameLabel, data: data, borderColor: randomColor, pointHoverBackgroundColor: '#fff', });
            });
        }
        chartDataObj = ({ userName: userName, labels: labels, datasets: dataset });
        setChartData(chartDataObj);
    }

    const onSlection = (e, data) => {
        data.isSelected = e.target.checked;
        if (data.isSelected) {
            props.salesList.forEach((listVal) => {
                if (listVal.user_id === data.user_id) {
                    setSelectedusers([...selectedUser, listVal]);
                    props.selectedRow([...selectedUser, listVal]);
                }
            });
        } else {
            onUnselect(data);
        }
    }

    const onUnselect = (data) => {
        let selectedRows = [...selectedUser];
        if (selectedRows.length > 0) {
            const index = selectedRows.findIndex(val => val.user_id === data.user_id);
            selectedRows.splice(index, 1);
            setSelectedusers([...selectedRows]);
            if (!selectedRows.length) {
                props.selectedRow(null);
            } else {
                props.selectedRow([...selectedRows]);
            }
        }
    }

    const headerTemplate = (data) => {
        return (
            <>
                <label className="custom-checkbox" tab-index="0" aria-label="Checkbox Label">
                    <input type="checkbox" defaultChecked={data.isSelected} onChange={e => onSlection(e, data, !data.isSelected)} />
                    <span className="checkmark"></span>
                </label>
                <span className="image-text">{data.user_name}</span>
            </>
        );
    }
    const ColBodyTemplate = (data) => {
        return (
            <>
                <span>{data.name}</span>
            </>
        );
    }

    const pointTemplate = (rowData, column) => {
        const field = column.field;
        let clr = '';
        ctxState.pointColors?.colors_data_for_5?.forEach((val) => {
            if (val.points_list.includes(rowData[`${field}`])) {
                clr = val.color;
            }
        });
        return <Badge value={rowData[`${field}`]} size="large" severity="success" style={{ background: `${clr}`, color: 'white' }}  ></Badge>
    }
    const onFilterChange = (values) => {
        setClearBtn(true);
        let newValues = helperService.filterSalesReportData(props.dataTableList, tabledata, values.filters, globalFilter, props.userData);
        setTableData([...newValues])
    }

    const onGlobalFilterChange = (e) => {
        setGlobalFilters(e)
        if (e === "All") {
            setTableData(props.dataTableList);
        } else {
            let newArr = props.dataTableList.filter(x => x.name === e);
            setTableData([...newArr]);
        }

    }

    const clearFilters = () => {
        setClearBtn(false);
        setIntialFilters();
        if (globalFilter === "All") {
            setTableData(props.dataTableList);
        } else {
            let newArr = props.dataTableList.filter(x => x.name === globalFilter);
            setTableData([...newArr]);
        }
    }

    return (
        <>
            <div className="card">
                <div className="flex justify-content-between">

                    {tabledata.length > 0 && <Dropdown
                        options={globalFilterOptions} value={globalFilter}
                        onChange={(e) => onGlobalFilterChange(e.value)} className="dropdowns mb-2 w-2" placeholder="Select a filter" />}

                    {
                        clearBtn && <Button icon="pi pi-times" type="button" className="mb-2" onClick={() => clearFilters()}></Button>}

                </div>

                <DataTable value={tabledata} rowGroupMode="subheader" groupRowsBy="user_id" showGridlines
                    sortMode="single" sortField="representative.user_name" sortOrder={1} responsiveLayout="scroll"
                    rowGroupHeaderTemplate={headerTemplate} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    filterDisplay="menu" rows={20} paginator filters={filters} onFilter={onFilterChange} >

                    <Column field="name" header="Name" body={ColBodyTemplate}></Column>
                    {props?.allCol && props?.allCol[0]?.columns_list.map((column, index) => {
                        return <Column key={index} field={column.column_name} header={column.header_name} showClearButton={false} filter dataType="numeric" body={pointTemplate} ></Column>
                    })}

                </DataTable>
            </div>

            <Dialog visible={props.chartDialog} style={{ width: '55%' }} header=" Sales Report" modal className="p-fluid" onHide={() => props.onChartHide()}>
                <div className="card flex justify-content-center">
                    <h4>{chartData.userName}</h4>
                    <Chart type="radar" data={chartData} options={lightOptions} style={{ position: 'relative', width: '70%' }} />
                </div>
            </Dialog>

        </>);
}

export default memo(SalesListView);