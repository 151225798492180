import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import Dashboard from './components/Dashboard/Dashboard';
import Corporaters from './components/Corporaters/Corporaters';
import CompetenceReport from './components/Reports/CompetenceReport/CompetenceReport';
import SalesReport from './components/Reports/SalesReport/SalesReport';
import BehaviourReport from './components/Reports/BehaviourReport/Behaviour';
import Motivation from './components/Reports/MotivationReport/MotivationReport';
import DiscountCode from './components/DiscountCodes/DiscountCode';
import TeamStrengthReport from './components/Reports/TeamStrength/TeamStength'
import Users from './components/Users/Users';
import { Tooltip } from "primereact/tooltip";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import NotFound from './pages/NotFound'

const App = (props) => {

    const [resetActiveIndex, setResetActiveIndex] = useState(null)
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode] = useState('sidebar');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle] = useState('filled');
    const [ripple] = useState(true);
    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [topbarScheme] = useState('light')
    const [menuScheme] = useState('light')
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const [userRole] = useState(JSON.parse(localStorage.getItem('user_role')));
    let menu = [];
    if (userRole && userRole.user_type_id === 3) {
        menu = [
            {
                label: 'Dashboard', icon: 'pi pi-home', to: '/'
            },
            {
                label: 'Reports', icon: 'pi pi-book',
                items: [
                    { label: 'Competence', icon: 'pi pi-star', to: '/competence-report' },
                    { label: 'Sales', icon: 'pi pi-dollar', to: '/sales-report' },
                    { label: 'Behaviour', icon: 'pi pi-star', to: '/behaviour-report' },
                    { label: 'Motivation', icon: 'pi pi-star', to: '/motivation-report' },
                    { label: 'Team Strength', icon: 'pi pi-star', to: '/team-strength-report' },
                ]
            },
        ];
    } else {
        menu = [
            {
                label: 'Dashboard', icon: 'pi pi-home', to: '/'
            },
            {
                label: 'Corporates', icon: 'pi pi-pencil', to: '/corporates'
            },
            {
                label: 'Reports', icon: 'pi pi-book',
                items: [
                    { label: 'Competence', icon: 'pi pi-star', to: '/competence-report' },
                    { label: 'Sales', icon: 'pi pi-dollar', to: '/sales-report' },
                    { label: 'Behaviour', icon: 'pi pi-star', to: '/behaviour-report' },
                    { label: 'Motivation', icon: 'pi pi-star', to: '/motivation-report' },
                    { label: 'Team Strength', icon: 'pi pi-star', to: '/team-strength-report' },
                ]
            },
            {
                label: 'Users', icon: 'pi pi-user', to: '/users'
            },
            {
                label: 'Discount Codes', icon: 'pi pi-dollar', to: 'discount-codes'
            },
        ];
    }


    let menuClick;
    let rightPanelClick;
    let configClick;
    let searchClick;
    let topbarUserMenuClick;


    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)
    }, [menuMode])

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setResetActiveIndex(true)
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };

    const onMenuClick = (event) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false)
        }
        menuClick = true;
    }



    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setRightPanelActive(false);

        if (isMobile()) {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };

    const isMobile = () => {
        return window.innerWidth <= 991;
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };



    const onRightPanelButtonClick = () => {
        setRightPanelActive((prevState) => !prevState)
        rightPanelClick = true;
    };

    const onTopbarSearchToggle = () => {
        setSearchActive(prevState => !prevState);
        searchClick = true;
    };

    const onTopbarSearchClick = () => {
        searchClick = true
    };

    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive(prevState => !prevState);
        topbarUserMenuClick = true;
    };



    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false)
            searchClick = false;
        }

        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false)
            topbarUserMenuClick = false;
        }

        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false);
        }

        if (!configClick && configActive) {
            setConfigActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true)
                setMenuActive(false)
            }

            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        searchClick = false;
        topbarUserMenuClick = false;
        rightPanelClick = false;
        configClick = false;
        menuClick = false;
    };

    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };

    const onSidebarMouseLeave = () => {
        setSidebarActive(false)
    };

    const onToggleMenu = (event) => {
        menuClick = true;
        setSidebarStatic(prevState => !prevState);

        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-sidebar': menuMode === 'sidebar',
        'layout-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-horizontal': menuMode === 'horizontal',
        'layout-rightpanel-active': rightPanelActive,
        'layout-slim': menuMode === 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    }, 'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme);

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar topbarScheme={topbarScheme} onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive} onTopbarSearchToggle={onTopbarSearchToggle} onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive} onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu} menuActive={menuActive} onRootMenuItemClick={onRootMenuItemClick} mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick} menuMode={menuMode}
                sidebarStatic={sidebarStatic} sidebarActive={sidebarActive} onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu} onMenuButtonClick={onMenuButtonClick} resetActiveIndex={resetActiveIndex} onMenuClick={onMenuClick} />

            <div className="layout-main">
                <div className="layout-content">
                    <Route path="/" exact={true} component={Dashboard} />
                    {userRole?.user_type_id !== 3 && <Route path="/corporates" exact={true} component={Corporaters} />}
                    <Route path="/competence-report" exact={true} component={CompetenceReport} />
                    <Route path="/sales-report" exact={true} component={SalesReport} />
                    <Route path="/behaviour-report" exact={true} component={BehaviourReport} />
                    <Route path="/motivation-report" exact={true} component={Motivation} />
                    <Route path="/team-strength-report" exact={true} component={TeamStrengthReport} />
                    {userRole?.user_type_id !== 3 && <Route path="/discount-codes" exact={true} component={DiscountCode} />}
                    {userRole?.user_type_id !== 3 && <Route path="/users" exact={true} component={Users} />}
                    <Route path="*" element={NotFound} />
                </div>
            </div>

            <div className="layout-mask modal-in"></div>
        </div>
    );

}

export default App;
