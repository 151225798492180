
export default class CommonHelperService {

    exportExcel (data, fileName) {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    saveAsExcelFile (buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });
                module.default.saveAs(data, fileName + '_report_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    filterSalesReportData (actualArr, filteredArray, filterValues, globalFilter, userData) {

        let dataTableData = []
        let newArr = filteredArray;
        for (const column in filterValues) {
            let columnValues = filterValues[`${column}`].constraints[0];

            if (globalFilter === 'Competency' && columnValues.value) {
                if (filterValues[`${column}`].value === null) {
                    newArr = actualArr.filter(x => x.name === "Competency");
                } else {
                    switch (columnValues.matchMode) {
                        case "equals":
                            newArr = newArr.filter(x => x[`${column}`] === +columnValues.value);
                            break;
                        case "notEquals":
                            newArr = newArr.filter(x => x[`${column}`] !== +columnValues.value);
                            break;
                        case "lt":
                            newArr = newArr.filter(x => x[`${column}`] < +columnValues.value);
                            break;
                        case "lte":
                            newArr = newArr.filter(x => x[`${column}`] <= +columnValues.value);
                            break;
                        case "gt":
                            newArr = newArr.filter(x => x[`${column}`] > +columnValues.value);
                            break;
                        case "gte":
                            newArr = newArr.filter(x => x[`${column}`] >= +columnValues.value);
                            break;
                        default:
                            break;
                    }
                }
            } else if (globalFilter === 'Attitude' && columnValues.value) {
                if (filterValues[`${column}`].value === null) {
                    newArr = actualArr.filter(x => x.name === "Attitude");
                } else {
                    switch (columnValues.matchMode) {
                        case "equals":
                            newArr = newArr.filter(x => x[`${column}`] === +columnValues.value);
                            break;
                        case "notEquals":
                            newArr = newArr.filter(x => x[`${column}`] !== +columnValues.value);
                            break;
                        case "lt":
                            newArr = newArr.filter(x => x[`${column}`] < +columnValues.value);
                            break;

                        case "lte":
                            newArr = newArr.filter(x => x[`${column}`] <= +columnValues.value);
                            break;

                        case "gt":
                            newArr = newArr.filter(x => x[`${column}`] > +columnValues.value);
                            break;

                        case "gte":
                            newArr = newArr.filter(x => x[`${column}`] >= +columnValues.value);
                            break;
                        default:
                            break;
                    }
                }
            } else if (globalFilter === 'All' && columnValues.value) {

                if (filterValues[`${column}`].value === null) {
                    newArr = actualArr;
                } else {
                    switch (columnValues.matchMode) {
                        case "equals":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] === +columnValues.value || user.competency[`${column}`] === +columnValues.value
                            );
                            break;
                        case "notEquals":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] !== +columnValues.value || user.competency[`${column}`] !== +columnValues.value
                            );
                            break;
                        case "lt":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] < +columnValues.value || user.competency[`${column}`] < +columnValues.value
                            );
                            break;

                        case "lte":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] <= +columnValues.value || user.competency[`${column}`] <= +columnValues.value
                            );
                            break;

                        case "gt":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] > +columnValues.value || user.competency[`${column}`] > +columnValues.value
                            );
                            break;

                        case "gte":
                            userData = userData.filter(user =>
                                user.attitude[`${column}`] >= +columnValues.value || user.competency[`${column}`] >= +columnValues.value
                            );
                            break;
                        default:
                            break;
                    }
                }
                userData.forEach((val) => {
                    const valKeys = Object.keys(val);
                    valKeys.forEach((key, i, arr) => {
                        if (typeof val[`${key}`] === 'object' && val[`${key}`]) {
                            val[`${key}`]['isSelected'] = false;
                            dataTableData.push(val[`${key}`]);
                        }
                    });
                });

                newArr = dataTableData;
            }
        }

        return newArr;
    }


} 