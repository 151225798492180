import React, { useState, useRef, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import DataService from '../../service/AssistantToolApi-service'
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
const AddDiscountCode = (props) => {

    let statuses = [
        { id: "0" },
        { id: "1" },
    ]

    let emptyCodes = {
        test_id: [],
        code: "",
        description: "",
        code_status: false,
        start: new Date(),
        end: new Date(),
        usage_limit: "",
        price: "",
        internal: "",
        company_id: []
    };

    const assessmentsOpt = [
        { id: 31, name: 'Personality & Behaviour' },
        { id: 32, name: 'Values & Motivation' },
        { id: 33, name: 'Verbal Reasoning' },
        { id: 34, name: 'Numerical Reasoning' },
        { id: 37, name: 'Logical Reasoning' },
        { id: 38, name: 'English' },
    ]

    const [discountCodes, setDiscountCodes] = useState(emptyCodes);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companyList, setCompanyList] = useState([]);
    const toast = useRef(null);
    const api = new DataService();

    useEffect(() => {
        if (props.modalData) {
            setPropsData();
        } else {
            setDiscountCodes(emptyCodes);
        } // eslint-disable-next-line
    }, [props.modalData, companyList]);

    useEffect(() => {
        if (!companyList.length) {
            api.getCompaniesNameAndIds(setCompanyList);
        } // eslint-disable-next-line
    }, [])

    const setPropsData = () => {
        let newAssesmentArr = [];
        let newCompanyArr = [];
        let editData = { ...props.modalData };
        editData.start = new Date(editData.start);
        editData.end = new Date(editData.end);
        editData.code_status = editData.status === 1 ? true : false;
        if (companyList.length && editData.company_id) {
            editData.company_id.forEach((val) => {
                companyList.forEach((company) => {
                    if (val === company.id) {
                        newCompanyArr.push(company);
                    }
                })
            });
            editData.company_id = newCompanyArr;
        } else{
            editData.company_id = [];
        }
        if (editData.test_id.length) {
            editData.test_id.forEach((val) => {
                assessmentsOpt.forEach((asses) => {
                    if (val === asses.id) {
                        newAssesmentArr.push(asses);
                    }
                })
            });
            editData.test_id = newAssesmentArr;
        }
        setDiscountCodes(editData);
    }

    const changeHandler = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _corporaters = { ...discountCodes };
        _corporaters[`${name}`] = val;
        setDiscountCodes(_corporaters);
    }

    const hasNullProp = (obj) => {
        for (const key of Object.keys(obj)) {
            if (obj[key] === '' || obj[key] === null) {
                return true
            }
        }
        return false
    }

    const setDataForAPI = () => {
        let newData = { ...discountCodes }
        let myStartDate = new Date(newData.start);
        let myEndDate = new Date(newData.end);
        newData.start = `${myStartDate.getFullYear()}-${(myStartDate.getMonth() + 1)}-${myStartDate.getDate()} ${myStartDate.getHours()}:${myStartDate.getMinutes()}:${myStartDate.getSeconds()}`;
        newData.end = `${myEndDate.getFullYear()}-${(myEndDate.getMonth() + 1)}-${myEndDate.getDate()} ${myEndDate.getHours()}:${myEndDate.getMinutes()}:${myEndDate.getSeconds()}`;
        newData.internal = newData.internal.id;
        newData.test_id = newData.test_id.map(val => val.id);
        newData.company_id = newData.company_id.map(val => val.id);
        newData.code_status = newData.code_status === true ? 1 : 5;
        return newData;
    }

    const addDiscountCode = () => {
        setSubmitted(true);
        setLoading(true);
        if (hasNullProp(discountCodes) === true || !discountCodes.test_id.length) {
            setLoading(false);
            return;
        }

        const payload = setDataForAPI();

        if (!discountCodes?.id) {

            api.addDiscountCode(payload)
                .then((res) => {
                    if (res.data.code === 200 || res.data.code === 201) {
                        setLoading(false);
                        props.hideDialog(res.data.message);
                        api.addEvent(7);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: `Something went wrong`, life: 3000 });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    error = error.response.data;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `${error.message}`, life: 3000 });
                });
        } else {
            api.updateDiscountCode(payload)
                .then((res) => {
                    if (res.data.code === 200 || res.data.code === 201) {
                        setLoading(false);
                        props.hideDialog(res.data.message);
                        api.addEvent(8);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: `Something went wrong`, life: 3000 });
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    error = error.response.data;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `${error.message}`, life: 3000 });

                });
        }


    }

    const copyLink = () => {
        navigator.clipboard.writeText(`https://talent.mazhr.com/?shash=${discountCodes.code}`);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `Link has been copied`, life: 3000 });
    }

    return (
        <>
            <div className="grid">
                <Toast ref={toast} />

                <div className="col-12">

                    <div >
                        <div className="p-fluid formgrid grid">
                            <div className="field col-10 lg:col-10">
                                <label htmlFor="code">Title</label>
                                <InputText id="code" placeholder="Start Typing..." type="text" value={discountCodes.code} onChange={(e) => changeHandler(e, 'code')} required autoFocus className={classNames({ 'p-invalid': submitted && !discountCodes.code })} />
                                {submitted && !discountCodes.code && <small style={{ color: 'red' }}>Code title is required</small>}
                            </div>

                            <div className="field col-2 lg:col-2 flex flex-column justify-content-center">
                                <label htmlFor="code_status">Status</label>
                                <InputSwitch checked={discountCodes.code_status} onChange={(e) => changeHandler(e, 'code_status')} />
                            </div>

                            <div className="field col-12 lg:col-6">
                                <label htmlFor="test_id"> Assessment </label>
                                <MultiSelect value={discountCodes?.test_id} options={assessmentsOpt} onChange={(e) => changeHandler(e, 'test_id')} optionLabel="name" placeholder="Select a assesment" display="chip" className={classNames({ 'p-invalid': submitted && !discountCodes.test_id?.length })} />
                                {submitted && !discountCodes.test_id?.length && <small style={{ color: 'red' }}>Assessment are required.</small>}
                            </div>

                            <div className="field col-12 lg:col-6">
                                <label htmlFor="test_id"> Company </label>
                                <MultiSelect value={discountCodes?.company_id} options={companyList} onChange={(e) => changeHandler(e, 'company_id')} optionLabel="name" placeholder="Select a company" display="chip" className={classNames({ 'p-invalid': submitted && !discountCodes.company_id?.length })} />
                                {/* <Dropdown optionLabel="name" options={companyList} placeholder="Select Company" value={discountCodes.company_id} onChange={(e) => changeHandler(e, 'company_id')} required className={classNames({ 'p-invalid': submitted && !discountCodes.company_id })} /> */}
                                {submitted && !discountCodes.company_id?.length && <small style={{ color: 'red' }}>Select a company.</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="usage_limit">Usage Limit</label>
                                <InputText id="usage_limit" type="text" value={discountCodes.usage_limit} onChange={(e) => changeHandler(e, 'usage_limit')} required className={classNames({ 'p-invalid': submitted && !discountCodes.usage_limit })} />
                                {submitted && !discountCodes.usage_limit && <small style={{ color: 'red' }}>Usage Limit is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="price">Price</label>
                                <InputText id="price" type="text" value={discountCodes.price} onChange={(e) => changeHandler(e, 'price')} required className={classNames({ 'p-invalid': submitted && !discountCodes.price })} />
                                {submitted && !discountCodes.price && <small style={{ color: 'red' }}>Price is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="internal">Internal</label>
                                <Dropdown optionLabel="id" options={statuses} placeholder="Select Internal" value={discountCodes.internal} onChange={(e) => changeHandler(e, 'internal')} required className={classNames({ 'p-invalid': submitted && !discountCodes.internal })} />
                                {/* <InputText id="internal" type="text" value={discountCodes.internal} onChange={(e) => changeHandler(e, 'internal')} required className={classNames({ 'p-invalid': submitted && !discountCodes.internal })} /> */}
                                {submitted && !discountCodes.internal && <small style={{ color: 'red' }}>Internal is required</small>}
                            </div>
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="business_type">Start Date</label>
                                <Calendar dateFormat="yy-mm-dd" id="time24" value={discountCodes.start} onChange={(e) => changeHandler(e, 'start')} showTime showSeconds required className={classNames({ 'p-invalid': submitted && !discountCodes.start })} />
                                {submitted && !discountCodes.start && <small style={{ color: 'red' }}>Start time is required</small>}
                            </div>
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="business_type">End Date</label>
                                <Calendar dateFormat="yy-mm-dd" id="time24" value={discountCodes.end} onChange={(e) => changeHandler(e, 'end')} showTime showSeconds required className={classNames({ 'p-invalid': submitted && !discountCodes.end })} />
                                {submitted && !discountCodes.end && <small style={{ color: 'red' }}>End time is required</small>}
                            </div>
                            <div className="field col-12 ">
                                <label htmlFor="description" style={{ margin: 0 }}><h5> Description</h5> </label>
                                <InputTextarea id="description" type="text" placeholder="Start typing..." autoResize rows="3" cols="30" value={discountCodes.description || ''} onChange={(e) => changeHandler(e, 'description')} required className={classNames({ 'p-invalid': submitted && !discountCodes.description })} />
                                {submitted && !discountCodes.description && <small style={{ color: 'red' }}>Description is required.</small>}
                            </div>

                        </div>
                        <div className="flex">
                            {props?.modalData && <Button icon="pi pi-copy" label="Copy Discount Code Link" className="p-button-success mr-2" onClick={() => { copyLink() }} />}
                            <Button label="Save" loading={loading} icon="pi pi-check" type="button" onClick={addDiscountCode}></Button>
                        </div>

                    </div>
                </div>
            </div>
        </>);
}

export default AddDiscountCode;