import React, { useState, useRef, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import DataService from '../../service/AssistantToolApi-service'
import { companyContext } from './Corporaters';
const AddCorporate = (props) => {
    let employeeCount = [
        { title: "1-10" },
        { title: "11-50" },
        { title: "51-150" },
        { title: "151- 300" },
        { title: "300-1000" },
        { title: "1001+" }
    ]
    const toast = useRef(null);
    let emptyCorporaters = {
        first: "",
        last: "",
        email: "",
        password: "",
        contact_phone: "",
        company_name: "",
        employee_count: "",
        industry_id: ''
    };
    const industry = useContext(companyContext);
    const [corporaters, setCorporaters] = useState(emptyCorporaters);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [businessType] = useState(industry.industryList);

    const changeHandler = (e, name) => {

        let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validPassword = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

        let val = (e.target && e.target.value) || '';
        let _corporaters = { ...corporaters };
        _corporaters[`${name}`] = val;

        if (_corporaters['email'] && !validEmail.test(_corporaters['email'])) {
            setEmailError("Email address must include @ and .")
        } else {
            setEmailError(null);
        }
        if (_corporaters['password'] && !validPassword.test(_corporaters['password'])) {
            setPasswordError("Password must contain a capital letter at least 8 characters and a special symbol")
        } else {
            setPasswordError(null);
        }
        setCorporaters(_corporaters);
    }

    const hasNullProp = (obj) => {
        for (const key of Object.keys(obj)) {
            if (obj[key] === '') {
                return true
            }
        }
        return false
    }

    const addCorp = () => {
        setSubmitted(true);
        setLoading(true);

        if (hasNullProp(corporaters) === true) {
            setLoading(false);
            return;
        }
        
        let payload = {
            first: corporaters.first,
            last: corporaters.last,
            email: corporaters.email,
            password: corporaters.password,
            contact_phone: corporaters.contact_phone,
            company_name: corporaters.company_name,
            employee_count: corporaters.employee_count.title,
            industry_id: corporaters.industry_id.id + ""
        };

        const api = new DataService();
        api.registerCompany(payload)
            .then((res) => {
                setLoading(false);
                api.addEvent(1);
                props.hideDialog();
            })
            .catch((error) => {
                setLoading(false);
                const err = error.response;
                if (err.status === 400) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: `${err.data.message}`, life: 3000 });
                }
            });
    }

    return (
        <>
            <div className="grid">
                <Toast ref={toast} />
                <div className="col-12">
                    <div >
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 lg:col-6">
                                <label htmlFor="first">Firstname</label>
                                <InputText id="first" type="text" value={corporaters.first} onChange={(e) => changeHandler(e, 'first')} required autoFocus className={classNames({ 'p-invalid': submitted && !corporaters.first })} />
                                {submitted && !corporaters.first && <small style={{ color: 'red' }}>First name is required</small>}
                            </div>

                            <div className="field col-12 lg:col-6">
                                <label htmlFor="last">Lastname</label>
                                <InputText id="last" type="text" value={corporaters.last} onChange={(e) => changeHandler(e, 'last')} required className={classNames({ 'p-invalid': submitted && !corporaters.last })} />
                                {submitted && !corporaters.last && <small style={{ color: 'red' }}>Last name is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" type="email" value={corporaters.email} onChange={(e) => changeHandler(e, 'email')} required className={classNames({ 'p-invalid': (submitted && !corporaters.email) || emailError })} />
                                {(emailError) && <small style={{ color: 'red' }}>{emailError}</small>}
                                {(submitted && !corporaters.email && !emailError) && <small style={{ color: 'red' }}>Email is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="password">Password</label>
                                <Password id="password" type="password" value={corporaters.password} onChange={(e) => changeHandler(e, 'password')} required className={classNames({ 'p-invalid': (submitted && !corporaters.password) || passwordError })} />
                                {(passwordError) && <small style={{ color: 'red' }}>{passwordError}</small>}
                                {(submitted && !corporaters.password && !passwordError) && <small style={{ color: 'red' }}>Password is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="contact_phone">Contact Number</label>
                                <InputMask id="contact_phone" value={corporaters.contact_phone} onChange={(e) => changeHandler(e, 'contact_phone')} required className={classNames({ 'p-invalid': submitted && !corporaters.contact_phone })} mask="(999) 999-9999" ></InputMask>
                                {submitted && !corporaters.last && <small style={{ color: 'red' }}>Contact number is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="company_naem">Companny Name</label>
                                <InputText id="company_naem" type="text" value={corporaters.company_name} onChange={(e) => changeHandler(e, 'company_name')} required className={classNames({ 'p-invalid': submitted && !corporaters.company_name })} />
                                {submitted && !corporaters.company_name && <small style={{ color: 'red' }}>Company name is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="business_type">Buisness Type</label>
                                <Dropdown optionLabel="name" options={businessType} placeholder="Select type" value={corporaters.industry_id} onChange={(e) => changeHandler(e, 'industry_id')} required className={classNames({ 'p-invalid': submitted && !corporaters.industry_id })} />
                                {/* <InputText id="industry_id" type="text" value={corporaters.industry_id} onChange={(e) => changeHandler(e, 'industry_id')} required className={classNames({ 'p-invalid': submitted && !corporaters.industry_id })} /> */}
                                {submitted && !corporaters.industry_id && <small style={{ color: 'red' }}>Buisness type is required</small>}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="business_type">Employee Count</label>
                                <Dropdown optionLabel="title" options={employeeCount} placeholder="Select employee count" value={corporaters.employee_count} onChange={(e) => changeHandler(e, 'employee_count')} required className={classNames({ 'p-invalid': submitted && !corporaters.employee_count })} />
                                {submitted && !corporaters.employee_count && <small style={{ color: 'red' }}>Employee count is required</small>}
                            </div>

                        </div>
                        <div className="flex justify-content-end">
                            <Button label="Save" loading={loading} icon="pi pi-check" type="button" onClick={addCorp}></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default AddCorporate;