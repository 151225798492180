import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import DataService from '../../service/AssistantToolApi-service';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment'
const TimeLine = () => {
    const api = new DataService();
    const [eventList, setEventList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageNo, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalPage, setTotalPage] = useState(false);

    useEffect(() => {
        if (!eventList.length) {
            getEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEvents = () => {
        setLoader(true);
        api.getEvents(pageNo, size).then((res) => {
            setLoader(false);
            if (res.data.code !== 404) {
                setEventList(prev => [...prev, ...res.data.data?.event_list]);
                setTotalPage(res.data.data?.total_pages);
            }
        })
            .catch((error) => {
                setLoader(false);
            });
    }

    const setData = (refresh) => {
        if (refresh) {
            setPage(1);
            setSize(10);
            setEventList([]);
            getEvents();
        } else {
            setPage((pageNo) => (pageNo + 10));
            setSize(1);
            getEvents();
        }
    }

    return (
        <>
            <div className="col-12">
                <div className="card timeline">
                    <div className="card-header">
                        <div className="card-title flex justify-content-between col-12">
                            <h6>Timeline</h6>
                            {loader ? <i className="pi pi-spin pi-spinner" ></i> : <i className="pi pi-sync pointer" onClick={() => setData(true)} ></i>}
                        </div>
                    </div>
                    {!eventList?.length ? <ProgressSpinner /> :
                        <ul>
                            {eventList && eventList?.map((event, index) => {
                                return event.event_type === 1 || event.event_type === 3 ?
                                    <li key={index} className="green">
                                        <i className="pi pi-circle-on"></i>
                                        <div className="event-content">
                                            <span className="event-title">{event.event_type_name}</span>
                                            <span>
                                                {event.user.name} has {event.event_type_name.toLowerCase()}.
                                            </span>
                                            <span className="time">{moment(event.created).fromNow()}</span>
                                        </div>
                                    </li> :

                                    <li key={index} className="blue">
                                        <i className="pi pi-circle-on"></i>
                                        <div className="event-content">
                                            <span className="event-title">{event.event_type_name}</span>
                                            <span>
                                                {event.user.name} has {event.event_type_name?.toLowerCase()}.
                                            </span>
                                            <span className="time">{moment(event.created).fromNow()}</span>
                                        </div>
                                    </li>
                            })}
                            {totalPage > pageNo ? <li className="justify-content-center">
                                <Button label="Load more" loading={loader} className="p-button-success mr-2" onClick={() => setData(false)} />
                            </li> : ''}
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}

export default TimeLine;
