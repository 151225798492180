
import axios from 'axios'

export default class AssitantToolApi {


    authBaseUrl = "https://assistant-api.mazhr.com/client/v1/";
    // authBaseUrl = 'http://192.168.0.51:8000/client/v1/';
    // apiBaseUrl = "https://b2b-python-api.8e2l0h3e6ump0.eu-west-1.cs.amazonlightsail.com/v1/";
    // apiBaseUrl = "http://192.168.0.51:5100/v1/";
    apiBaseUrl = "https://corporate-api.mazhr.com/v1/";


    getAccessToken() {
        const accessToken = localStorage.getItem('access');
        if (accessToken) {
            return accessToken;
        } else {
            return false;
        }
    }

    getRefreshToken() {
        const refreshToken = localStorage.getItem('refresh');
        if (refreshToken) {
            return refreshToken;
        } else {
            return false;
        }
    }

    logIn = (payload) => {
        return axios.post(this.authBaseUrl + 'auth/login/', payload);
    }

    logOut() {
        const refresh = this.getRefreshToken();
        return axios.post(this.authBaseUrl + 'auth/logout/', { refresh });
    }

    refreshToken() {
        const refresh = this.getRefreshToken();
        return axios.post(this.authBaseUrl + 'auth/login/refresh/', { refresh })
    }

    getCompanyList() {
        return axios.get(this.apiBaseUrl + 'company/all/');
    }

    getCompaniesNameAndIds(callback) {
        axios.get(this.apiBaseUrl + 'company/all/').then((res) => {
            if (res.data.data) {
                res = res.data.data;
                res = res.map(({ id, name }) => ({ id, name }))
                callback(res);
            }
        })
    }

    registerCompany(payload) {
        return axios.post(this.apiBaseUrl + 'company/register/', payload);
    }

    editCompanyInfo(payload, companyId) {
        return axios.put(this.apiBaseUrl + `company/info/${companyId}`, payload);
    }

    makeCompanyActiveInactive(company_id, isActive) {
        if (isActive) {
            return axios.post(this.apiBaseUrl + `company/${company_id}/activate/1`);
        } else {
            return axios.post(this.apiBaseUrl + `company/${company_id}/activate/0`);
        }
    }

    filterCompanies(status) {
        return axios.get(this.apiBaseUrl + `company/all/${status}`);
    }

    getCompanyPool(companyId) {
        return axios.get(this.apiBaseUrl + `pools/${companyId}`);
    }

    addCompanyPool(payload) {
        return axios.post(this.apiBaseUrl + 'pool/', payload);
    }
    getCompanyTeamList(companyId) {
        return axios.get(this.apiBaseUrl + `teams/${companyId}`);
    }
    addCompanyTeam(payload) {
        return axios.post(this.apiBaseUrl + 'team/', payload);
    }
    editCompanyTeam(payload) {
        return axios.put(this.apiBaseUrl + `team/`, payload);
    }

    editCompanyPool(payload) {
        return axios.put(this.apiBaseUrl + `pool/${payload.hash}`, payload);
    }

    getAssessments() {
        return axios.get(this.apiBaseUrl + 'assessments/')
    }

    getIndustriesList() {
        return axios.get(this.apiBaseUrl + 'job-industries/');
    }

    getTargetProfiles() {
        return axios.get(this.apiBaseUrl + 'target-profiles/');
    }

    getEvents(page, size) {
        return axios.get(this.authBaseUrl + `events/?page_number=${page}&page_size=${size}`);
    }
    addEvent(type) {
        // type 1 add company
        // type 2 edit company
        // type 3 add pool
        // type 4 edit poo
        axios.post(this.authBaseUrl + 'events/', { event_type: type });
    }

    getCompetenceReports(payload) {
        return axios.get(this.authBaseUrl + `user/competence_points?`, {
            params: {
                company_id: payload.company_id, pool_hash: payload.hash_list, discount_code_id: payload.discount_code_id,
                is_user_pool: payload.is_user_pool, is_discount_code: payload.is_discount_code
            }
        });
    }

    getSalesReport(payload) {
        return axios.get(this.authBaseUrl + `user/sales_functions?`, {
            params: {
                company_id: payload.company_id, pool_hash: payload.hash_list, discount_code_id: payload.discount_code_id,
                is_user_pool: payload.is_user_pool, is_discount_code: payload.is_discount_code
            }
        });
    }

    getConfig() {
        return axios.get(this.authBaseUrl + 'config');
    }

    getBehaviourReports(payload) {
        return axios.get(this.authBaseUrl + `user/behaviour_points?`, {
            params: {
                company_id: payload.company_id, pool_hash: payload.hash_list, discount_code_id: payload.discount_code_id,
                is_user_pool: payload.is_user_pool, is_discount_code: payload.is_discount_code
            }
        });
    }

    getMotivationReports(payload) {
        return axios.get(this.authBaseUrl + `user/motivation_points?`, {
            params: {
                company_id: payload.company_id, pool_hash: payload.hash_list, discount_code_id: payload.discount_code_id,
                is_user_pool: payload.is_user_pool, is_discount_code: payload.is_discount_code
            }
        });
    }

    getUsers(companyId, pool_id, type, email, industry_id, startDate, endDate, target_profile_id, assessment, limit, ) {
        return axios.get(this.authBaseUrl + `user/get_all_users?`, {
            params: {
                company_id: companyId, pool_hash: pool_id, type: type?.id, email: email, industry_id: industry_id,
                start_date: startDate, end_date: endDate, target_profile_id: target_profile_id, assessments_done: assessment, limit: limit, page: 0
            }
        });
    }

    getCompanyEmail(companyId) {
        return axios.get(this.authBaseUrl + `get_corp_user_email?company_id=${companyId}`);
    }

    updateCompanyCredentials(payload) {
        return axios.put(this.authBaseUrl + `update_corp_user/`, payload);
    }

    getDiscountCodeList(code, limit) {
        return axios.get(this.authBaseUrl + `discount_codes/`, {
            params: {
                code: code, limit: limit, page: 0
            }
        });
    }

    addDiscountCode(payload) {
        return axios.post(this.authBaseUrl + `discount_codes/`, payload);
    }

    updateDiscountCode(payload) {
        return axios.put(this.authBaseUrl + `discount_codes/`, payload);
    }

    deleteCode(id) {
        return axios.delete(this.authBaseUrl + `discount_codes/`, { data: { id: id } });
    }

    markCodeActiveInActive(payload) {
        const data = { id: payload.id, code_status: payload.status };
        return axios.patch(this.authBaseUrl + `discount_codes/`, data);
    }

    getTeamStrengthReport(payload) {
        return axios.post(this.apiBaseUrl + `team-strength-report/`, payload);
    }

    getTeams(id) {
        return axios.get(this.apiBaseUrl + `team/?company_id=${id}`);
    }

    getKPIData(start_date, end_date) {
        return axios.get(this.authBaseUrl + `dashboard`, {
            params: { start_date: start_date, end_date: end_date }
        });
    }

    getUserDetail(user_id) {
        return axios.get(this.apiBaseUrl + `user/user_details/?user_id=${user_id}`, );
    } 

 SendAssessmentReminder = (userId, company_id, callbackFunction) => {
    const data = {

          'company_id': company_id // Add the company ID to the headers
        
      };
    return axios.post(this.apiBaseUrl + `assessment-reminder/` + userId  , data )
    .then((res) => {
      if (res.status === 200) {
        callbackFunction(true, res);
      } else {
        callbackFunction(false);
      }
    })
    .catch((error) => {
      callbackFunction(false, error);
    });
};

}

