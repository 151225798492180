import React, { useState, useEffect, useRef } from 'react';
import { DataView } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import ProductService from '../../../service/ProductService';
import { Chart } from 'primereact/chart';
import ReportListView from '../ReportListView'
import DataService from '../../../service/AssistantToolApi-service';
import HelperService from '../../../service/CommonHelperService';
import { Button } from 'primereact/button';
import ReportFilters from '../ReportFilters'

const MotivationReport = () => {

    const api = new DataService();
    const helperService = new HelperService();
    const [dataviewValue, setDataviewValue] = useState(null);
    const [layout] = useState('list');
    const [groupList, setGroupList] = useState(null);
    const [group, setGroup] = useState(null);
    const [reportList, setReortList] = useState([]);
    const [column, setColumn] = useState([]);
    const [pie, setPie] = useState([]);
    const [loader, setLoader] = useState(false);
    const dt = useRef(null);

    useEffect(() => {
        const productService = new ProductService();
        productService.getProducts().then(data => setDataviewValue(data));
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const pieOptions = {
        plugins: {
            legend: {
                labels: {
                    color: 'black'
                }
            }
        }
    };

    const getReports = (payload) => {
        setLoader(true);
        api.getMotivationReports(payload).then((res) => {
            setLoader(false);
            res = res.data.data;
            setReortList(res);
            let groupList = [];
            for (let i = 0; i < res.group_list.length; i++) {
                groupList.push({ id: res.group_list[i].group_name, name: res.group_list[i].group_name });
            }
            setGroupList(groupList);
        }).catch((error) => { });
    }

    const convertToPieData = (list, coloumns) => {

        let pieData = {};
        let labels = [];
        let backgroundColor = [];
        let coloumnShown = [];
        let pieArr = [];

        coloumns.forEach((val, index) => {
            labels.push(val.header_name);
            backgroundColor.push(val.color);
            coloumnShown.push(val.column_name);
        });

        list.forEach((val, index) => {
            let data = [];
            data.push({ labels: labels })
            const keys = Object.keys(val);
            for (let i = 0; i < keys.length; i++) {
                for (let j = 0; j < coloumnShown.length; j++) {
                    if (coloumnShown[j] === keys[i]) {
                        data.push(val[`${coloumnShown[j]}`]);
                        pieData = {
                            datasets: [{
                                data: data,
                                backgroundColor: backgroundColor
                            },
                            ],
                            labels: labels,
                        }
                    }
                }
            }
            pieArr.push({ pieData: pieData, name: val.first_name + " " + val.last_name });

        });
        setPie(pieArr);
    }

    const onGroupChange = (e) => {
        
        const val = (e.target && e.target.value) || '';
        setGroup(val);
        const groups = reportList.group_list;
        const columns = groups.find(grp => grp.group_name?.toLowerCase().trim() === val.id?.toLowerCase().trim())?.columns_list;
        setColumn(columns);
        convertToPieData(reportList.user_data, columns);
    }

    const exportCSV = () => {
        
        helperService.exportExcel(reportList?.user_data, 'Motivational')
    }

    const dataviewListItem = (data) => {
        return (
            <>
                <ReportListView list={reportList?.user_data} column={column} forwardedRef={dt} />
            </>
        );
    };

    const enableLoader = (e) => {
        setLoader(e);
    }

    const dataviewGridItem = (data) => {
        return (
            <>
                {!pie.length ? <div className="card bg-lightGray flex justify-content-center ">No data found</div> :
                    <>
                        {pie.map((data, index) => {
                            return <div className="col-12 md:col-4" key={index}>
                                <div className="card m-3 border-1 surface-border" >
                                    <div className="text-center">
                                        <div className="flex justify-content-center">
                                            <Chart type="pie" data={data.pieData} options={pieOptions} style={{ width: '50%' }} />
                                        </div>
                                        <div className="text-2xl font-bold">{data.name}</div>
                                    </div>
                                </div>
                            </div>

                        })}
                    </>
                }
            </>);
    };

    const itemTemplate = (data, layout) => {
        if (!data) {
            return;
        }
        if (layout === 'list') {
            return dataviewListItem(data);
        }
        else if (layout === 'grid') {
            return dataviewGridItem(data);
        }
    };

    const dataviewHeader = (
        <div className="grid grid-nogutter">
            <div className="col-6" style={{ textAlign: 'left' }}>
                <Dropdown options={groupList} value={group} optionLabel="name" onChange={(e) => onGroupChange(e)} placeholder="Sort By Group" />
            </div>
            {/* <div className="col-6" style={{ textAlign: 'right' }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
            </div> */}
        </div>
    );

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <div className="flex justify-content-between" style={{ marginBottom: '1rem' }}>
                        <div className="flex">
                            <h5 className="mr-2">Motivation Reports</h5>
                            <ReportFilters callReportsData={getReports} enableLoader={enableLoader} />
                            {loader ? <div> <i className="pi pi-spin pi-spinner" ></i></div> : ''}
                        </div>
                        <div>
                            {reportList?.user_data ?
                                <Button type="button" icon="pi pi-file-excel" className="p-button-success mr-2" data-pr-tooltip="XLS" onClick={() => exportCSV()} /> : ''}
                        </div>
                    </div>
                    <DataView value={dataviewValue} layout={layout} itemTemplate={itemTemplate} header={dataviewHeader}></DataView>
                </div>
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MotivationReport, comparisonFn);