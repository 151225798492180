import { useState, useEffect, useRef } from "react";
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import DataService from './../../service/AssistantToolApi-service';

const ReportFilters = ({callReportsData,enableLoader}) => {
    const api = new DataService();
    const [companyList, setCompanyList] = useState(null);
    const [company, setCompany] = useState(null);
    const [poolList, setPoolList] = useState([]);
    const [pool, setPool] = useState([]);
    const [isCompany, setCompanyCheck] = useState("Company");
    const [userRole] = useState(JSON.parse(localStorage.getItem('user_role')));
    const [discountCodeLIST, setDiscountCodeLIST] = useState([]);
    const [discountCode, setDiscountCode] = useState([]);
    const [checked, setChecked] = useState(true);
    let [teams, setTeams] = useState([]);
    let [timer, setTimer] = useState(0);
    let [selectedTeams, setSelectedTeams] = useState("");
    const op = useRef(null);

    useEffect(() => {
        if (!companyList) {
            getCompaniesList();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getCompaniesList = () => {
        enableLoader(true);
        api.getCompanyList()
            .then((res) => {
                if (res.data.data) {
                    let list = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        if (userRole?.user_type_id === 3 && userRole?.company_id === res.data.data[i].id) {
                            list = [];
                            list.push({ id: res.data.data[i].id, name: res.data.data[i].name });
                            setCompany(list[0]);
                            getCompanyPools(res.data.data[i].id);
                            getTeams(res.data.data[i].id);
                            createPayload(res.data.data[i].id, []);
                            enableLoader(false);
                            break;
                        }
                        list.push({ id: res.data.data[i].id, name: res.data.data[i].name });
                    }
                    enableLoader(false);
                    setCompanyList(list);
                }
            }).catch((error) => { });
    }

    const getCompanyPools = (id) => {
        api.getCompanyPool(id)
            .then((res) => {
                res = res.data.data;
                let poolList = [];
                poolList.push({ id: 'All', name: 'All' });
                for (let i = 0; i < res.length; i++) {
                    poolList.push({ id: res[i].hash, name: res[i].title });
                }
                setPoolList(poolList);
            })
            .catch((error) => { })
    }

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setCompany(val);
        getTeams(val.id);
        getCompanyPools(val.id);
        createPayload(val.id, []);
    }

    const onDiscountChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setDiscountCode(val);
        createPayload(1, "", val.id);
    }

    const getTeams = (id) => {
        api.getTeams(id).then((res) => {
            res = res.data.data;
            let newTeams = [];
            for (let index = 0; index < res.length; index++) {
                newTeams.push({ id: res[index]?.hash, name: res[index]?.title });
            }
            setTeams([...newTeams]);
        })
    }

    const toggleRadioButton = (e) => {
        setCompanyCheck(e.value);
        if (!discountCodeLIST.length) {
            api.getDiscountCodeList().then((res) => {
                let newArr = res.data?.data?.data;
                newArr = (newArr)?.map(({ code, id }) => {
                    return { 'id': id, 'name': code }
                });
                setDiscountCodeLIST([...newArr]);
            }).catch((err) => { })
        }
    }

    const onTeamChanges = (e) => {
        enableLoader(true);
        const val = (e.target && e.target.value) || '';
        setSelectedTeams(val);
        let newVal = [...val];
        newVal = newVal.map(x => x.id);
        if (timer) { window.clearTimeout(timer) }
        let time = setTimeout(() => {
            createPayload(company.id, newVal);
        }, 1500);
        setTimer(time);
    }

    const onPoolChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setPool(val);
        val.id === "All" ? createPayload(company.id, []) : createPayload(company.id, [val.id]);
    }

    const createPayload = (id, hash, discountId) => {
        const payload = {
            company_id: id,
            hash_list: hash,
            is_user_pool: checked,
            is_discount_code: isCompany === 'Company' ? false : true,
            discount_code_id: discountId
        }
        callReportsData(payload);
    }
    return (
        <>
            <Button type="button" icon="pi pi-filter" className="p-button-success mr-2" data-pr-tooltip="XLS" onClick={(e) => op.current.toggle(e)} />
            <OverlayPanel ref={op} id="overlay_panel" style={{ width: '350px' }} className="overlaypanel-demo">

                <div className="filter-pannel">

                    <span className="flex justify-content-around">
                        <div className="field-radiobutton">
                            <RadioButton inputId="city1" name="city" value="Company" onChange={(e) => setCompanyCheck(e.value)} checked={isCompany === 'Company'} />
                            <label htmlFor="city1">Company</label>
                        </div>
                        <div className="field-radiobutton">
                            <RadioButton inputId="city2" name="city" value="Discount Codes" onChange={(e) => toggleRadioButton(e)} checked={isCompany === 'Discount Codes'} />
                            <label htmlFor="city2">Discount Codes</label>
                        </div>
                    </span>



                    {isCompany === 'Company' && <div className="filter-pannel">
                        <Dropdown options={companyList} optionLabel="name" value={company} onChange={(e) => onCompanyChange(e)} placeholder="Select Company" className="mb-2" filter={true}/>
                        <span className="flex justify-content-between">
                            {checked && <Dropdown options={poolList} optionLabel="name" value={pool} onChange={(e) => onPoolChange(e)} placeholder="Select Pool" className="mb-2 w-10" />}
                            {!checked && <MultiSelect value={selectedTeams} options={teams} onChange={(e) => onTeamChanges(e)} optionLabel="name" placeholder="Select Teams" maxSelectedLabels={3} className="mb-2 w-10" />}
                            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                        </span>
                    </div>}

                    {isCompany === 'Discount Codes' && <Dropdown options={discountCodeLIST} optionLabel="name" value={discountCode} onChange={(e) => onDiscountChange(e)} placeholder="Select Discount Code" className="mb-2" />}
                </div>

            </OverlayPanel>
        </>
    );
}


export default ReportFilters;