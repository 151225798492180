import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import DataService from '../../service/AssistantToolApi-service';
import { Toast } from 'primereact/toast';

const DiscountCodeList = (props) => {
    let [reportList, setReportList] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const toast = useRef(null);
    const api = new DataService();
    useEffect(() => {
        if (props.list) {
            setReportList(props?.list);
        }
    }, [props]);// eslint-disable-line react-hooks/exhaustive-deps


    const scrollLoad = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            props.setPages(50);
        }
    }

    const onDeleteSelect = (data) => {
        setDeleteDialog(true);
        setSelectedRow(data);
    }

    const deleteCode = () => {

        setDeleteDialog(false);
        api.deleteCode(selectedRow.id).then((res) => {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Successfully Deleted', life: 3000 });
            props.delete();
        }).catch((err) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
        });

    }



    const actionBodyTemplate = (rowData) => {

        const markActiveInActive = (e) => {
            rowData.status = e.target.value ? 1 : 5;
            updateList();
            props.showLoader(true);
            api.markCodeActiveInActive(rowData).then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Status Updated Successfully', life: 3000 });
                props.showLoader(false);
            }).catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong', life: 3000 });
                props.showLoader(false);
            })
        }

        const updateList = () => { 
            let index = reportList.findIndex(report => report.id === rowData.id);
            reportList.splice(index, 1, rowData);
            setReportList([...reportList]);
        }

        return (
            <>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => props.edit(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => onDeleteSelect(rowData)} />
                <InputSwitch checked={rowData.status === 1} onChange={(e) => markActiveInActive(e)} />
            </>
        );
    }

    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text p-button-danger" onClick={() => deleteCode()} />
        </>
    );

    return (
        <>
            <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={() => setDeleteDialog(false)}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {selectedRow && <span>Are you sure you want to delete <b> {selectedRow.code} </b> ?</span>}
                </div>
            </Dialog>
            <Toast ref={toast} />
            <div className="col-12" >
                <div className="card" >
                    <DataTable ref={props.forwardedRef} value={reportList} responsiveLayout="scroll" scrollHeight="600px" scrollDirection="both" className="mt-3"
                        scrollable virtualScroll virtualScrollerOptions={{ onScroll: scrollLoad, itemSize: 50, showLoader: true, }} >
                        <Column field="code" sortable header="Code" style={{ flexGrow: 1, flexBasis: '160px', justifyContent: 'center' }} frozen></Column>
                        <Column field="description" sortable header="Description" style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }} alignFrozen="left"></Column>
                        <Column field="price" sortable header="Price" style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }} alignFrozen="left"></Column>
                        <Column field="start" sortable header="Start" style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }} alignFrozen="left"></Column>
                        <Column field="end" sortable header="End" style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }} alignFrozen="left"></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>
                </div>
            </div>


        </>
    );
}
export default DiscountCodeList;