import React, { useEffect, useState } from 'react';
import AssitantToolApi from '../../service/AssistantToolApi-service';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';


const KpiData = () => {

    const [kpiData, setKpiData] = useState({});
    const [dates, setDates] = useState([]);


    const [intervalOptions, setIntervalOptions] = useState([]);
    const [selectedInterval, setSelectedInterval] = useState(null);

    const [assessmentBarChartData, setAssessmentsBarChartData] = useState(null);
    const [candidateBarChartData, setCandidateBarChartData] = useState(null);
    const [teamMembersBarChartData, setTeamMembersBarChartData] = useState(null);

    const api = new AssitantToolApi();

    const onDateChange = (e) => {
        setDates(e.value);
        if (e.value[0] && e.value[1]) {
            let startDate = e.value[0].toISOString().slice(0, 10);
            let endDate = e.value[1].toISOString().slice(0, 10);
            callKpiData(startDate, endDate);
        }
    }

    useEffect(() => {
        const today = new Date();
        const startDate = today.toISOString().slice(0, 10);
        const sevenDaysAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        callKpiData(sevenDaysAgo, startDate);
        // eslint-disable-next-line
    }, []);


    const setChartData = (jsonData, countItemKey, dataSetLabel, chartDataFunction, interval) => {
        if (jsonData) {
            const dataObjects = jsonData[interval];
            const data = dataObjects.map((item) => item[countItemKey]);
            const label = dataObjects.map((item) => item.name);
            const chartData = {
                labels: label,
                datasets: [
                    {
                        label: dataSetLabel,
                        data: data,
                        backgroundColor: '#42A5F5', // Specify the bar color
                    },
                ],
            };
            chartDataFunction(chartData)
        }
    }

    const callKpiData = (startDate, endDate) => {
        api.getKPIData(startDate, endDate).then(
            (res) => {
                setKpiData(res.data.data.dashboard_data);

                let assessmentsJsonData = res.data.data.dashboard_data.assessments_companies_timeseries
                const keysArray = Object.keys(assessmentsJsonData).map((key, index) => ({interval: key, index: index}));
                setIntervalOptions(keysArray)

                if (keysArray.length > 0) {
                    // Collect all keys in an array
                    const keys = Object.keys(assessmentsJsonData);

                    setSelectedInterval(keysArray[0])

                    setChartData(
                        assessmentsJsonData,
                        'assessments_count',
                        "Assessments Count",
                        setAssessmentsBarChartData,
                        keys[0]
                    );

                    let candidatesJsonData = res.data.data.dashboard_data.candidates_companies_timeseries
                    setChartData(
                        candidatesJsonData,
                        'candidate_count',
                        "New Candidates Count",
                        setCandidateBarChartData,
                        keys[0]
                    );

                    let teamMembersJsonData = res.data.data.dashboard_data.members_companies_timeseries
                    setChartData(
                        teamMembersJsonData,
                        'member_count',
                        "New Members Count",
                        setTeamMembersBarChartData,
                        keys[0]
                    );
                }
            });
    }

    const onSelectInterval = (val) =>{
        setSelectedInterval(val)

        let assessmentJsonData = kpiData.assessments_companies_timeseries
        setChartData(assessmentJsonData,'assessments_count', "Assessments Count", setAssessmentsBarChartData, val.interval);

        let candidatesJsonData = kpiData.candidates_companies_timeseries
        setChartData(candidatesJsonData,'candidate_count', "New Candidates Count", setCandidateBarChartData, val.interval);

        let teamMembersJsonData = kpiData.members_companies_timeseries
        setChartData(teamMembersJsonData,'member_count', "New Members Count", setTeamMembersBarChartData, val.interval);
    }

    const barChartOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        barThickness: 40, // Specify the bar thickness (width)
        barPercentage: 0, // Specify the bar height as a percentage of available space
        scales: {
            x: {
                beginAtZero: true,
                precision: 0,
                ticks: {
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                beginAtZero: true,
                precision: 0,
                grid: {
                    drawBorder: false
                }
            }
        }
    };

    return (
        <>

            <div className="col-12 pb-0">
                <span className='flex justify-content-start'>
                    <Calendar placeholder='Please select date range' className='w-3 mb-3' selectionMode="range" value={dates} onChange={(e) => onDateChange(e)} showIcon readOnlyInput />
                </span>

                <div className="grid">

                    <div className="col">
                        <div className="card overview-box softgreen">
                            <div className="overview-info">
                                <h6>New companies</h6>
                                <h1>{kpiData.new_companies_count || 0}</h1>
                            </div>
                            <i className="pi pi-users"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box mint-green">
                            <div className="overview-info">
                                <h6>New positions</h6>
                                <h1>{kpiData.new_positions_count || 0}</h1>
                            </div>
                            <i className="pi pi-globe"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box darkgray">
                            <div className="overview-info">
                                <h6>New teams</h6>
                                <h1>{kpiData.new_teams_count || 0}</h1>
                            </div>
                            <i className="pi pi-th-large"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box orange">
                            <div className="overview-info">
                                <h6>New team members</h6>
                                <h1>{kpiData.new_team_members_count || 0}</h1>
                            </div>
                            <i className="pi pi-cloud"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box darkpink">
                            <div className="overview-info">
                                <h6>New candidates</h6>
                                <h1>{kpiData.new_candidates_count || 0}</h1>
                            </div>
                            <i className="pi pi-user-plus"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box darkpurple">
                            <div className="overview-info">
                                <h6>Assessments done</h6>
                                <h1>{kpiData.assessments_done_count || 0}</h1>
                            </div>
                            <i className="pi pi-check"></i>
                        </div>
                    </div>

                    {/*<div className="col">
                        <div className="card overview-box orange">
                            <div className="overview-info">
                                <h6>People hired through Mazhr</h6>
                                <h1>{kpiData.people_hired_through_mazhr || 0}</h1>
                            </div>
                            <i className="pi pi-cloud"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box default-green">
                            <div className="overview-info">
                                <h6>Invites sent</h6>
                                <h1>{kpiData.invites_pending_count || 0}</h1>
                            </div>
                            <i className="pi pi-send"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box softgreen">
                            <div className="overview-info">
                                <h6>Invites accepted</h6>
                                <h1>{kpiData.invites_accepted_count || 0}</h1>
                            </div>
                            <i className="pi pi-thumbs-up"></i>
                        </div>
                    </div>

                    <div className="col">
                        <div className="card overview-box maroon">
                            <div className="overview-info">
                                <h6>Invites declined</h6>
                                <h1>{kpiData.invites_declined_count || 0}</h1>
                            </div>
                            <i className="pi pi-trash"></i>
                        </div>
                    </div>*/}

                </div>

                {intervalOptions.length > 0 && <div className="flex justify-content-start">
                    <Dropdown
                        value={selectedInterval}
                        onChange={
                            (e) => {
                                onSelectInterval(e.value)
                            }
                        }
                        options={intervalOptions}
                        optionLabel="interval"
                        placeholder="Select Month"
                        className="w-full md:w-14rem"
                    />
                </div>}
                <br/>
                <br/>
                {teamMembersBarChartData && <div className="card">
                    <h1>New Team Members Per Corporate</h1>
                    <Chart type="bar" data={teamMembersBarChartData} options={barChartOptions} />
                </div>}
                {candidateBarChartData && <div className="card">
                    <h1>New Candidates Per Corporate</h1>
                    <Chart type="bar" data={candidateBarChartData} options={barChartOptions} />
                </div>}
                {assessmentBarChartData !== null && <div className="card">
                    <h1>Assessments Per Corporate</h1>
                    <Chart type="bar" data={assessmentBarChartData} options={barChartOptions} />
                </div>}

            </div>
        </>
    )
}

export default KpiData
