import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useHistory } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import Api from '../service/AssistantToolApi-service';
export const Login = () => {
	let emptyUser = {
		email: "",
		password: ""
	};
	const history = useHistory();
	const [user, setUser] = useState(emptyUser);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [loading, setLoading] = useState(false);
	const toast = useRef(null);

	const submitLogin = () => {
		if (!user.email || emailError) { setEmailError("Email address must include @ and ."); return null };
		if (!user.password) { setPasswordError("Please enter a valid password"); return null };
		setLoading(true);
		let api = new Api();
		api.logIn(user).then((response) => {
			localStorage.setItem('access', response.data.data.access);
			localStorage.setItem('refresh', response.data.data.refresh);
			if (response.data.data.user_role) {
				localStorage.setItem('user_role', JSON.stringify(response.data.data.user_role));
			}
			toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Login Successfully', life: 3000 });
			setTimeout(() => {
				history.push('/')
			}, 800);
		})
			.catch(function (error) {
				setLoading(false);
				const errorRes = error.response;
				toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorRes.data.message}`, life: 3000 });
			});

	}

	const changeHandler = (e, name) => {
		let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const val = (e.target && e.target.value) || '';
		let _user = { ...user };
		_user[`${name}`] = val;

		if (_user.email && !validEmail.test(_user.email)) {
			setEmailError("Email address must include @ and .")
		} else {
			setEmailError(null);
		}

		setUser(_user);
	}

	return (

		<div className="login-body">
			<Toast ref={toast} />
			<div className="login-wrapper">
				<div className="login-panel">
					<button className="logo p-link">
						<img src="assets/layout/images/mazhr-logo-with-name.svg" alt="Mazhr" />
					</button>

					<InputText id="email" placeholder="Email" value={user.email} onChange={(e) => changeHandler(e, 'email')} required autoFocus className={classNames({ 'p-invalid': emailError })} />
					{emailError && <small style={{ color: 'red' }}>{emailError}</small>}
					<Password id="password" placeholder="Password" toggleMask feedback={false} value={user.password} onChange={(e) => changeHandler(e, 'password')} className={classNames({ 'p-invalid': passwordError })} />
					{passwordError && <small style={{ color: 'red' }}>{passwordError}</small>}
					<br />
					<Button label="LOGIN" loading={loading} type="button" onClick={submitLogin}></Button>

				</div>

			</div>
		</div >
	)
}
