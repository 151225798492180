import React, {useState} from 'react'; 
import { TabView, TabPanel } from 'primereact/tabview';
import CompanyPoolsList from './Company-Pools-List';
import CompanyTeamsList from './Company-Teams-List';

export default function TabsView({poolList, toast, closeDialog, pool, setPool, poolDialog, setPoolDialog, teamList, team, setTeam, teamDialog, setTeamDialog}) {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className="card">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Pools">
                <CompanyPoolsList poolList={poolList} toast={toast} closeDialog={closeDialog} pool={pool} setPool={setPool} poolDialog={poolDialog} setPoolDialog={setPoolDialog}/>
                </TabPanel>
                <TabPanel header="Teams">
                <CompanyTeamsList teamList={teamList} team={team} setTeam={setTeam} teamDialog={teamDialog} setTeamDialog={setTeamDialog}/>
                </TabPanel>
            </TabView>
        </div>
    )
}