import React, { useState, useEffect, useContext, useRef } from 'react';
import TabsView from './Tabs-View';
import DataService from '../../service/AssistantToolApi-service';
import { Toast } from 'primereact/toast';
import { companyContext } from './Corporaters';
import './Corporates.scss'
const CompanyPools = () => {
    const [teamDialog, setTeamDialog] = useState(false)
    const [teamList, setTeamList] = useState(null);
    const [poolDialog, setPoolDialog] = useState(false);
    const [poolList, setPoolList] = useState(null);
    const [team, setTeam] = useState();
    const [pool, setPool] = useState([]);
    const company = useContext(companyContext);
    const toast = useRef(null);
    const api = new DataService();
    useEffect(() => {
        if (company?.id && !pool?.length) {
            getCompanyPool();
            getCompanyTeam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company?.id]);

    const getCompanyPool = () => {
        api.getCompanyPool(company.id)
            .then((res) => {
                res = res.data.data;
                setPoolList([...res]);
            })
            .catch((error) => { });
    }
    const getCompanyTeam = () => {
        api.getTeams(company.id)
            .then((res) => {
                res = res.data.data;
                setTeamList([...res]);
            })
            .catch((error) => { });
    }
    const closeDialog = () => {
        setPoolDialog(false);
        setTeamDialog(false);
        getCompanyPool();
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `Company added successfully`, life: 3000 });
    }


    return (
        <>
            <Toast ref={toast} />
            <div className="grid ">
                <div className="col-12">
                    <div className="card ">
                    <TabsView poolList={poolList} teamList={teamList} closeDialog={closeDialog} toast={toast} pool={pool} setPool={setPool} team={team} setTeam={setTeam} poolDialog={poolDialog} setPoolDialog={setPoolDialog} teamDialog={teamDialog} setTeamDialog={setTeamDialog}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompanyPools;