import React, { useEffect, useState, createContext } from 'react';
import { Route, withRouter, useLocation, useHistory } from 'react-router-dom';
import App from './App';
import { Login } from './pages/Login';
import Api from './service/AssistantToolApi-service';
import axios from 'axios'
export const ctx = createContext();

const AppWrapper = (props) => {
	const location = useLocation();
	const history = useHistory();
	const api = new Api();
	const isAuthenticated = api.getAccessToken();
	const [colorScheme, setColorScheme] = useState('light');
	const [pointColors, setPointColors] = useState([]);

	useEffect( () => {
		window.scrollTo( 0, 0);
		if (pointColors.length === 0) {
			getPointsColor();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, pointColors]);

	axios.interceptors.request.use(
		(config) => {
			const token = api.getAccessToken();
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
				config.headers['system'] = 'assistance_tool';
			}
			return config;
		},
		error => {
			Promise.reject(error)
		}
	);

	axios.interceptors.response.use(

		(response) => {
			return response
		},

		(error) => {
			const originalRequest = error.config;
			if (
				error.response.status === 401 &&
				originalRequest.url.includes('login')
			) {
				localStorage.clear();
				history.push('/')
				return Promise.reject(error)
			}

			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				return api.refreshToken()
					.then((res) => {
						if (res) {
							localStorage.setItem('access', res.data.access);
							axios.defaults.headers.common['Authorization'] =
								'Bearer ' + api.getAccessToken();
							return axios(originalRequest)
						}
					}).catch((error) => {
						localStorage.clear();
						history.push('/');
					})
			}
			return Promise.reject(error)
		}
	);

	const getPointsColor = () => {

		api.getConfig().then((res) => {
			setPointColors(res.data.data);
		}).catch((error) => {
			setPointColors({
				colors_data_for_5: [],
				colors_data_for_9: [],
		})
		});
	}

	if (!isAuthenticated) {
		return <Route path="/" render={() => <Login colorScheme={colorScheme} />} />
	} else {
		return <ctx.Provider value={{ pointColors: pointColors }}>
			<App setColorScheme={setColorScheme} />
		</ctx.Provider>;
	}
}

export default withRouter(AppWrapper);