import { useState, useContext, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import deleteIcon from "../../assets/icons/deleteIcon.png"
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import DataService from '../../service/AssistantToolApi-service';
import { classNames } from 'primereact/utils';
import { companyContext } from './Corporaters';
import { MultiSelect } from 'primereact/multiselect';
import "./Corporates.scss";

const TeamDetails = (props) => {
    useEffect(() => {
        if (props.team) {
            setPropsTeamData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    let emptyTeams = {
        industry: "",
        description: "",
        invite_team_member_email: "",
        member_count: null,
        members: [],
        title: "",
        assessments: [],
        hash: '',
        email_list: [],
    }

    const assessmentsOpt = [
        { id: 31, name: 'Personality & Behaviour' },
        { id: 32, name: 'Values & Motivation' },
        { id: 33, name: 'Verbal Reasoning' },
        { id: 34, name: 'Numerical Reasoning' },
        { id: 37, name: 'Logical Reasoning' },
        { id: 38, name: 'English' },

    ]
    const company = useContext(companyContext);
    const [team, setTeam] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [businessType] = useState(company.industryList);
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [emailError, setEmailError] = useState("");
  
    const handleInputChange = (event) => {
      setEmail(event.target.value);
    };

    const api = new DataService();




    const changeHandler = (e, name) => {
        let val = (e.target && e.target.value) || '';
        if (name === "department") {
            val = e.target?.value
        }
        let _team = { ...team };
        _team[`${name}`] = val;
        setTeam(_team);
    }

    // const hasNullProp = (obj) => {
    //     const newObj = Object.assign(emptyTeams, obj)
    //     for (const key of Object.keys(newObj)) {
    //         if (newObj[key] === '' && key !== 'hash') {
    //             return true
    //         }
    //     }
    //     return false
    // }

    const setPropsTeamData = () => {
        let editTeam = emptyTeams
        editTeam.industry = props.team.industry;
        editTeam.description = props.team.description;
        editTeam.title = props.team.title;
        editTeam.company_id = props.team.company_id;
        editTeam.assessments = setAssesmentsforDropdown(props.team?.assessments);
        editTeam.email_list = props.team.members.map((member) => member.email);
        editTeam.hash = props.team.hash || '';
        console.log(editTeam)
        setTeam(editTeam);
    }

    // useEffect(() => {
    //     console.log(team)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [team]);

    const setAssesmentsforDropdown = (arr) => {
        let assessmentArr = [];
        if (arr.length) {
            arr.forEach((val) => {
                assessmentsOpt.forEach((asses) => {
                    if (val === asses.id) {
                        assessmentArr.push(asses);
                    }
                })
            });
        }
        return assessmentArr;
    }

    const createPayLoad = (obj) => {
        let payload = {};
        if(obj.hash) {
            payload.hash = obj.hash
        }
        payload.title = obj.title;
        payload.description = obj.description;
        payload.industry_id = obj?.industry.id + "";
        if(obj.hash) {
            payload.company_id = +company?.id;
            console.log(payload.company_id)
        }
        else {
            payload.company_id = company?.id + '';
        }
        payload.assessments = obj.assessments.length ? obj?.assessments?.map(val => val.id) : [];
        payload.email_list = obj.email_list
        console.log(payload)
        return payload;
    }

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
          // Validate the email using a regular expression
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (emailRegex.test(email)) {
            // Add the email to the list
            if(props.team) {
                setTeam((prevTeam) => ({
                    ...prevTeam,
                    email_list: [...prevTeam.email_list, email]
                  }));
            }
            else {
                setEmailList((prevEmailList) => [...prevEmailList, email]);
                setTeam((prevteam) => ({
                    ...prevteam,
                    email_list: [...emailList, email]
                }))
            }
            setEmail(''); // Clear the input field after adding the email
            setEmailError(''); // Clear the input field after adding the email
          } else {
            setEmailError("Not a valid email Format")
          }
        }
      };

        const handleDelete = (index) => {
    // Create a copy of the emailList and remove the element at the specified index
    const updatedEmailList = [...team.email_list];
    // console.log(updatedEmailList)
    updatedEmailList.splice(index, 1);
    // console.log(updatedEmailList)
    // Set the updated email_list property of the team state
    setTeam({ ...team, email_list: updatedEmailList });
    };

    const submitResponse = () => {

        setSubmitted(true);
        setLoading(true);
        // if (hasNullProp(team) === true || !team.assessments?.length) {
        //     setLoading(false);
        //     return
        // }
        let payload = createPayLoad(team);
        if (team.hash && team.hash !== '') {
            payload.hash = team.hash || "";
            api.editCompanyTeam(payload)
                .then((res) => {
                    api.addEvent(4);
                    setLoading(false);
                    props.close();
                })
                .catch((error) => {
                    setLoading(false);
                })
        } else {
            api.addCompanyTeam(payload)
                .then((res) => {
                    api.addEvent(3);
                    setLoading(false);
                    props.close();
                    props.closeDialog()
                })
                .catch((error) => {
                    setLoading(false);
                })
        }
    }

    return (
        <>
            <div className="flex flex-column">
                {/* <div className="field col-12 openPool">
                    <label htmlFor="is_closed" style={{ margin: 0 }}> <h5>Close Pool</h5></label> &nbsp;&nbsp;
                    <InputSwitch checked={pool.is_closed} onChange={(e) => changeHandler(e, 'is_closed')} />
                </div> */}
                <div className="field col-12 ">
                    <label htmlFor="title" style={{ margin: 0 }}> <h5>New Team Title</h5></label>
                    <InputText id="title" type="text" autoFocus placeholder="Start typing..." value={team.title} onChange={(e) => changeHandler(e, 'title')} required className={classNames({ 'p-invalid': submitted && !team.title })} />
                    {submitted && !team.title && <small style={{ color: 'red' }}>Team title is required.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="department" style={{ margin: 0 }}> <h5> Industry/Field</h5>  </label>
                    <Dropdown optionLabel="name" options={businessType} placeholder="Select department" value={team.industry} onChange={(e) => changeHandler(e, 'industry')} required className={classNames({ 'p-invalid': submitted && !team.industry })} />
                    {submitted && !team.industry && <small style={{ color: 'red' }}>Please select a industry.</small>}
                </div>
                <input
                type="text"
                value={email}
                onChange={handleInputChange}
                onKeyDown={handleEnterPress}
                placeholder="Enter email and press Enter"
            />
            {emailError.length > 0 &&  <small style={{ color: 'red' }}>{emailError}</small>}
            {!props.team &&
                            <div className="field col-12 ">
                            <ul>
                            {emailList.map((email, index) => (
                            <li key={index}>{email}</li>
                            ))}
                        </ul>
                            </div>
            }
            
            {props.team && 
                <div className="field col-12 ">
                    <h5>Team Members</h5>
                        <ul style={{listStyle:"none", padding: "0px"}}>
                        {team?.email_list?.length !==0 ? team?.email_list?.map((email, index) => (
                        <li className='mb-3' key={index}>
                            <div className='bordered-members-item'>
                                <span>{email}</span>
                                <span><img height="15px" src={deleteIcon} alt='delete-icon' onClick={() => handleDelete(index)}/></span>
                            </div>
                        </li>
                        )):

                        <p>No Members Found</p>
                        }
                    </ul>
                </div>
            }

                <div className="field col-12 ">
                    <label htmlFor="description" style={{ margin: 0 }}><h5> Assessments</h5> </label>
                    <MultiSelect value={team.assessments} options={assessmentsOpt} onChange={(e) => changeHandler(e, 'assessments')} optionLabel="name" placeholder="Select a value" display="chip" className={classNames({ 'p-invalid': submitted && !team.assessments?.length })} />
                    {submitted && !team.assessments?.length && <small style={{ color: 'red' }}>Assessments are required.</small>}
                </div>
                <div className="field col-12 ">
                    <label htmlFor="description" style={{ margin: 0 }}><h5> Description</h5> </label>
                    <InputTextarea id="description" type="text" placeholder="Start typing..." autoResize rows="3" cols="30" value={team.description || ''} onChange={(e) => changeHandler(e, 'description')} required className={classNames({ 'p-invalid': submitted && !team.description })} />
                    {submitted && !team.description && <small style={{ color: 'red' }}>Description is required.</small>}
                </div>



            </div>


           { <div className="flex justify-content-end ">
                <Button loading={loading} label="Save" icon="pi pi-check" type="button" onClick={submitResponse} ></Button>
            </div>}
        </>
    );
}

export default TeamDetails;
