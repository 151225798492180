import React, { useState, useEffect, useRef, createContext } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import AddCorporate from './Add-Corporate';
import CorporateDetails from './Corporate-Details';
import { InputText } from 'primereact/inputtext';
import './Corporates.scss';
import DataService from '../../service/AssistantToolApi-service';
import CompanyPools from './Company-Pools';
import { InputSwitch } from 'primereact/inputswitch';
export const companyContext = createContext();
const Corporaters = () => {

    const [corporateDialog, setCorporateDialog] = useState(false);
    const [companies, setCompanies] = useState(null);
    const [industryList, setIndustryList] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [filteredCompanies, setFilteredCompanies] = useState(companies);
    const [targetProfile, setTargetProfile] = useState(null);
    const [company, setCompany] = useState(null);
    const [indexSelected, setIndex] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const [loader, setLoader] = useState(false);
    const toast = useRef(null);
    const api = new DataService();
    const aactiveOpt = [
        { id: '0', name: 'All' },
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'In-Active' },
    ];

    useEffect(()=>{
        const delay = setTimeout(() => {
            let filtered = companies?.filter((item) => {
                if (!searchInput) {
                    return item
                }

                return item.name.toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredCompanies(filtered)
        },1000);
        return () => {
            clearTimeout(delay)
        }
    },[searchInput,companies])

    useEffect(() => {
        if (!companies  && !industryList) {
            fetchCompaniesData();
            fetchIndustryList();
            fetchTargetProfiles();
        }
        if (!filteredCompanies) {
            setFilteredCompanies(companies)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies, industryList, filteredCompanies]);

    const fetchCompaniesData = () => {
        setLoader(true);
        api.getCompanyList()
            .then((res) => {
                if (res) {
                    setCompanies(res.data.data);
                    setCompany(res.data.data[0]);
                    setIndex(0);
                    setLoader(false);
                }
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
            });
    };

    const changeHandler = (e) => {
        setSearchInput(e.target.value)
    }

    const fetchIndustryList = () => {
        api.getIndustriesList()
            .then((res) => {
                if (res) {
                    setIndustryList(res.data);
                }
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
            });
    };

    const fetchTargetProfiles = () => {
        api.getTargetProfiles()
            .then((res) => {
                setTargetProfile(res.data);
            })
            .catch((error) => {
                const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
            });
    }

    const hideDialog = () => {
        setCorporateDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `Company added successfully`, life: 3000 });
        fetchCompaniesData();
    }


    const setCompanyData = (company, index, e) => {
        setCompany(company);
        setIndex(index);
        e.preventDefault()
    }

    const filterCompanines = (e) => {
        setLoader(true);
        setIsActive(e.value);
        if (e.value.id === '0') { fetchCompaniesData() }
        else {
            api.filterCompanies(e.value.id).then((res) => {
                res = res.data.data;
                setCompanies([]);
                setCompanies([...res]);
                setLoader(false);
            }).catch((error) => {
                setLoader(false);
                const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
            })
        }
    }

    const markActiveInActive = (e, company) => {
        api.makeCompanyActiveInactive(company.id, e.value).then((res) => {
            if (e.value) {
                api.addEvent(5);
            } else {
                api.addEvent(6);
            }
            let updateVal = companies.map(val => {
                if (val.id === company.id) {
                    val.active = e.value;
                }
                return val;
            });
            setCompanies([...updateVal]);
            e.value === true ? toast.current.show({ severity: 'success', summary: 'Successful', detail: `${company.name} marked as active`, life: 3000 }) :
                toast.current.show({ severity: 'success', summary: 'Successful', detail: `${company.name} marked as in-active`, life: 3000 });
        }).catch((error) => {
            const errorMsg = error.response?.data?.data?.msg || 'Something went wrong';
            toast.current.show({ severity: 'error', summary: 'Error', detail: `${errorMsg}`, life: 3000 });
        })
    }

    return (
        <>
            <companyContext.Provider value={{ id: company?.id, industryList: industryList, targetProfile: targetProfile }}>

                <div className="grid ">

                    <Toast ref={toast} />
                    <Dialog visible={corporateDialog} style={{ width: '45%' }} header="Add Corporates" modal className="p-fluid" onHide={() => setCorporateDialog(false)}>
                        <AddCorporate hideDialog={hideDialog}></AddCorporate>
                    </Dialog>

                    <div className="col-12">
                        <div className="card d-flex">

                            {!companies && !industryList ? <ProgressSpinner /> :
                                <>
                                    <div className="md:col-12 lg:col-6 list-mainDiv">
                                        <InputText onChange={changeHandler} placeholder="Search Companies"/>
                                        <div className="my-2 flex justify-content-between">
                                            <h5> Corporate List</h5>
                                            {loader ? <div> <i className="pi pi-spin pi-spinner" ></i></div> : ''}
                                            <span>
                                                <Dropdown style={{ marginRight: '0.5rem' }} placeholder="Filter Companies" optionLabel="name" options={aactiveOpt} value={isActive} onChange={(e) => filterCompanines(e)} />
                                                <Button label="Add corporates" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => setCorporateDialog(true)} />
                                            </span>
                                        </div>
                                        {filteredCompanies?.map((company, index) => {

                                            return (
                                                <div key={company.id} className="card bg-lightGray flex pointer" style={{ boxShadow: indexSelected === index ? '0px 9px 11px grey' : 'none' }} onClick={(e) => setCompanyData(company, index, e)}>
                                                    <button className="logo p-link col-1" style={{ padding: '0' }}>
                                                        <img src="assets/layout/images/mazhr-logo.svg" alt="Mazhr" />
                                                    </button>
                                                    <span className="col-4" style={{ padding: '0' }}>
                                                        <h5>{company.name}</h5>
                                                        <small>{company.industry.name}</small>
                                                    </span>
                                                    <span className="col-3" style={{ padding: '0' }}>
                                                        <p>{company.employee_count}</p>
                                                    </span>
                                                    <span className="col-4" style={{ padding: '0' }}>
                                                        <p>{company.contact_phone}</p>
                                                        <InputSwitch checked={company.active} onChange={(e) => markActiveInActive(e, company)} />
                                                    </span>
                                                </div>

                                            )
                                        })}

                                        {filteredCompanies?.length === 0 && 
                                        <div>
                                            <h2>
                                                No corporate Available
                                            </h2>
                                        </div>
                                         }
                                            



                                    </div>

                                    <div className="md:col-12 lg:col-6">

                                        <CorporateDetails company={company}></CorporateDetails>
                                        <CompanyPools></CompanyPools>

                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </companyContext.Provider>
        </>

    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Corporaters, comparisonFn);
