import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

 const NotFound = (props) => {

	const history = useHistory();
	
	const goDashboard = () => {
		history.push('/')
	}


	return (
		<div className="exception-body notfound">
			<div className="exception-wrapper">
				<div className="exception-content">
					<img src="assets/layout/images/pages/asset-404.svg" alt="Mazhr" />
					<span>404</span>
					<span className="exception-subtitle">Looks like you are lost.</span>
					<Button type="button" label="Go back to home" onClick={goDashboard} ></Button>
				</div>
			
			</div>
		</div>
	)
}
export default NotFound