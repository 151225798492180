import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import tickIcon from "../../assets/icons/tickIcon.png"
import crossIcon from "../../assets/icons/crossIcon.png"
import { Toast } from 'primereact/toast';
import DataService from '../../service/AssistantToolApi-service'
const UsersList = (props) => {
    const [reportList, setReportList] = useState([]);
    const api = new DataService();
  

    useEffect(() => {
        if (props.list) {
          const transformedArray = props?.list.map((object) => {
            const assessments = object.assessments.map((assessment) => {
              let updatedTitle = assessment?.test_name;
              if (assessment?.test_name === 'shapes') {
                updatedTitle =  'Personality and Behaviour'
              } 
              else if (assessment?.test_name === 'scales_lst') {
                updatedTitle = 'Logical Reasoning'
              } 
              else if (assessment?.test_name === 'scales_verbal') {
                updatedTitle = 'Verbal Reasoning'
              } 
              else if (assessment?.test_name === 'scales_numerical') {
                updatedTitle = 'Numerical Reasoning'
              }
              else if (assessment?.test_name === 'views') {
                updatedTitle = 'Values and Motivation'
              }
              else if (assessment?.test_name === 'scales_lt-e_basic') {
                updatedTitle = 'English'
              }
              else {
                // If the assessment doesn't match any of the replacements, keep it as it is
                return assessment;
              }
              return { ...assessment, test_name: updatedTitle };
            });
            // Create a new object with the updated assessments array and keep other properties unchanged
            return { ...object, assessments };
          });
            
            setReportList(transformedArray);
            
        }
    }, [props, props.id]);// eslint-disable-line react-hooks/exhaustive-deps

    const sendAssessmentReminder = (rowData) =>{
      
      const user_id = rowData.user_id; 
      const company_id = parseInt(rowData.company_id);
      
      
      // const updateTeamToast = Toast.loading(
      //   "Sending...",

      // );
     api.SendAssessmentReminder(user_id, company_id, (status, response) =>{
      
        if(status && response.ok && response.httpStatus === 200){
          Toast.show({
            render: "Reminder email sent",
            type:"success",
            isLoadng:"false",
            autoClose:"2000",
            closeButton:true,
            hiderProgressBar:true

          })
        }else{
            Toast.show({
              render:"unable to send reminder email",
              type:"error",
              isLoadng: false,
              autoClose:'2000',
              closeButton:true,
              hiderProgressBar:true
            })
          }
        }
      )
    }
   
    const renderActions = (rowData) => {
      return (
        <div className="sendToTests">
        <button className="mazhrBtn default-pink" onClick={() => sendAssessmentReminder(rowData)}>Resend assessments</button>
    </div>
      );
    };

    const renderAssessments = (rowData) => {
      const assessments = rowData.assessments || [];
      return (
          <ul className='table-assessments-list'>
              {assessments.map((assessment, index) => (
                  <div className='d-flex'>
                    {assessment?.score? <img src={tickIcon} height="24" alt='tick'/> : <img height="24" src= {crossIcon} alt='cross'/>}
                  <li key={index} style={{ alignSelf: 'center', margin: "0px 10px" }}>{assessment?.test_name}</li>
                  </div>

              ))}
          </ul>
      );
  };
  

    const scrollLoad = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            props.setPages(50)
        }
    }


    return (
        <>
            <div className="col-12" >
                <div className="card" >
                <DataTable
    ref={props.forwardedRef}
    value={reportList}
    responsiveLayout="scroll"
    scrollHeight="600px"
    scrollDirection="both"
    className="mt-3"
    scrollable
    virtualScroll
    virtualScrollerOptions={{ onScroll: scrollLoad, itemSize: 50, showLoader: true }}
  >
    <Column
      field="first_name"
      sortable
      header="First Name"
      style={{ flexGrow: 1, flexBasis: '160px', justifyContent: 'center' }}
      frozen
    />
    <Column
      field="last_name"
      sortable
      header="Last Name"
      style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }}
      alignFrozen="left"
    />
    <Column
      field="email"
      sortable
      header="Email"
      style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }}
      alignFrozen="left"
    />
    <Column
      field="assessments"
      sortable
      header="Assessments"
      style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center', flexDirection: "column" }}
      alignFrozen="left"
      body={renderAssessments}
    />
    <Column
  key="actions"
  header="Actions"
  style={{ flexGrow: 1, flexBasis: '100px', justifyContent: 'center' }}
  body={renderActions}
/>
  </DataTable>
                </div>
            </div>
        </>
    );
}
export default UsersList;