import React, { useState, useEffect, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DataService from '../../service/AssistantToolApi-service';
import { Password } from 'primereact/password';
import { companyContext } from './Corporaters';
import { classNames } from 'primereact/utils';

function UpdateCredentials (props) {

    let emptyCredentials = {
        email: '',
        password: ''
    };
    const [credentials, setCredentials] = useState(emptyCredentials);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const company = useContext(companyContext);
    const api = new DataService();
    useEffect(() => {

        let _credentials = { ...credentials };
        _credentials[`email`] = props.corporateEmail;
        setCredentials(_credentials);

        // eslint-disable-next-line
    }, [props])

    const changeHandler = (e, name) => {
        let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validPassword = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
        let val = (e.target && e.target.value) || '';
        let _credentials = { ...credentials };
        _credentials[`${name}`] = val;
        if (_credentials['email'] && !validEmail.test(_credentials['email'])) {
            setEmailError("Email address must include @ and .")
        } else {
            setEmailError(null);
        }
        if (_credentials['password'] && !validPassword.test(_credentials['password'])) {
            setPasswordError("Password must contain a capital letter at least 8 characters and a special symbol")
        } else {
            setPasswordError(null);
        }
        setCredentials(_credentials);
    }

    const hasNullProp = (obj) => {
        for (const key of Object.keys(obj)) {
            if (obj[key] === '') {
                return true
            }
        }
        return false
    }

    const submit = () => {
        setSubmitted(true);
        setLoading(true);

        if (hasNullProp(credentials) === true || passwordError || emailError) {
            setLoading(false);
            return;
        }
        credentials.company_id = company.id;
        api.updateCompanyCredentials(credentials).then((res) => {
            props.closeCredentials(true);
        }).catch((err) => {
            props.closeCredentials(false);
         })

    }
    return (
        <>
            <div className="p-fluid formgrid grid">
                <div className="field col-12">
                    <label htmlFor="company_naem">Email<span className={"mandatory-field"}>*</span></label>
                    <InputText id="email" type="text" value={credentials.email} onChange={(e) => changeHandler(e, 'email')} autoFocus className={classNames({ 'p-invalid': submitted && !credentials.email })} />
                    {(emailError) && <small style={{ color: 'red' }}>{emailError}</small>}
                    {(submitted && !credentials.email && !emailError) && <small style={{ color: 'red' }}>Email is required</small>}
                </div>
                <div className="field col-12">
                    <label htmlFor="business_type">Password<span className={"mandatory-field"}>*</span></label>
                    <Password id="password" feedback={false} type="password" value={credentials.password} onChange={(e) => changeHandler(e, 'password')} required  className={classNames({ 'p-invalid': submitted && !credentials.password })} />
                    {(passwordError) && <small style={{ color: 'red' }}>{passwordError}</small>}
                    {(submitted && !credentials.password && !passwordError) && <small style={{ color: 'red' }}>Password is required</small>}
                </div>
                <Button label="Save" loading={loading} className="p-button-check mr-2" onClick={submit} />

            </div>
        </>
    );
}

export default UpdateCredentials;