import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import DataService from '../../service/AssistantToolApi-service'
import UsersList from './UsersList'
const Users = () => {

    const today = new Date().toISOString().slice(0, 10);

    const assessmentOpt = [
        { id: '', name: 'All' },
        { id: '0', name: 'Completed' },
        { id: '1', name: 'Pending' },
    ]
    const [users, setUsers] = useState();
    const [dates, setDates] = useState([]);
    const [loader, setLoader] = useState(false);
    const [typeVal, setTypeVal] = useState("");
    const [companyList, setCompanyList] = useState(null);
    const [companyTeamList, setCompanyTeamList] = useState(null);
    const [company, setCompany] = useState(null);
    const [poolList, setPoolList] = useState(null);
    const [stats, setStats] = useState();
    const [pool, setPool] = useState('');
    const [team, setTeam] = useState('');
    const [industryList] = useState(null);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [industry] = useState(null);
    const [targetProfileList] = useState(null);
    const [targetProfile] = useState(null);
    const [assessment, setAssessment] = useState(null);
    const [page, setPageNo] = useState(50);
    const [email, setEmail] = useState('');
    const [lastPage, setLastPage] = useState(false);
    const api = new DataService();
    
    useEffect(() => {
        if (!users) {
            getUsers();
        } if (!companyList) {
            getCompaniesList();
        } if (!industryList) {
            // getIndustriesList();
        } if (!targetProfileList) {
            // getTargetProfiles();
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=> {
        if (startDate && endDate) {
            getUsers(
              company?.id,
              pool?.id? pool?.id: team?.id,
              typeVal,
              email,
              industry?.id,
              startDate,
              endDate,
              targetProfile?.id,
              assessment?.id,
              page
            );
          }
    },[startDate, endDate]);// eslint-disable-line react-hooks/exhaustive-deps

    const typeOptions = [{id: 0, name: "Pool"},{id: 1, name: "Team"}]

    const onDateChange = (e) => {
        setDates(e.value);
        if (e.value[0] && e.value[1]) {
            setStartDate(e.value[0].toISOString().slice(0, 10));
            setEndDate(e.value[1].toISOString().slice(0, 10));
            // getUsers(company?.id, pool?.id, email, industry?.id, startDate,endDate, assessment?.id, page);
        }
    }

    const getUsers = (company_id, pool_id, typeVal, email, industry_id, start_date, end_date, targetProfile_id, assessment_id, limits) => {
        setLoader(true);
        company_id = company_id || "";
        pool_id = pool_id || "";
        typeVal = typeVal || "";
        email = email || "";
        industry_id = industry_id || "";
        start_date = startDate || "";
        end_date = endDate || "";
        targetProfile_id = targetProfile_id || "";
        assessment_id = assessment_id || "";
        limits = limits || 50;

        api.getUsers(company_id, pool_id, typeVal, email, industry_id, start_date, end_date, targetProfile_id, assessment_id, limits)
            .then((res) => {
                if (res.data.data.user_data) {
                    setUsers([...res.data.data.user_data]);
                    setStats({...res.data.data.stats})
                    setLastPage(res.data.data.pagination.last_page);
                    setLoader(false);
                }
            }).catch((error) => {
                setLoader(false);
            });
    }

    const getCompaniesList = () => {
        setLoader(true);
        api.getCompanyList()
            .then((res) => {
                if (res.data.data) {
                    let list = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        list.push({ id: res.data.data[i].id, name: res.data.data[i].name });
                    }
                    list.unshift({id: null, name: 'All'})
                    setCompanyList(list);
                    setLoader(false);
                }

            }).catch((error) => {

            });
    }

    const getCompanyTeams = (id) => {
        setLoader(true);
        api.getCompanyTeamList(id? id: '')
            .then((res) => {
                if (res.data.data) {
                    let list = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        list.push({ id: res.data.data[i].hash, name: res.data.data[i].title });
                    }
                    list.unshift({id: null, name: 'All'})
                    setCompanyTeamList(list);
                    setLoader(false);
                }

            }).catch((error) => {

            });
    }

    // const getIndustriesList = () => {
    //     setLoader(true);
    //     api.getIndustriesList()
    //         .then((res) => {
    //             setIndustryList(res.data);
    //         })
    //         .catch((err) => { })
    // }

    // const getTargetProfiles = () => {
    //     setLoader(true);
    //     api.getTargetProfiles()
    //         .then((res) => {
    //             setTargetProfileList(res.data);
    //         })
    //         .catch((err) => { })
    // }


    const onTypeChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setTypeVal(val)
        if(val.name === "Pool") {
            setTeam(null)
        }
        else if(val.name === "Team") {
            setPool(null)
        }
    }

    const onTeamChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setTeam(val)
        getUsers(company?.id, val?.id? val?.id: null, typeVal, email, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
    }

    const onCompanyChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setPool(null)
        setTeam(null)
        setCompany(val);
        getCompanyPools(val.id);
        getCompanyTeams(val.id)
        getUsers(val?.id? val?.id: null, null, typeVal, email, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
    }

    const onPoolChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setPool(val);
        getUsers(company?.id, val?.id? val?.id: null , typeVal, email, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
    }

    // const onTeamChange = (e) => {
    //     const val = (e.target && e.target.value) || '';
    //     setTeam(val);
    //     getCompanyTeams(val.id)
    // }

    // const onIndustryChange = (e) => {
    //     const val = (e.target && e.target.value) || '';
    //     setIndustry(val);
    //     getUsers(company?.id, pool?.id, email, val?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
    // }

    // const onTargetProfileChange = (e) => {
    //     const val = (e.target && e.target.value) || '';
    //     setTargetProfile(val);
    //     getUsers(company?.id, pool?.id, email, industry?.id, startDate, endDate, val?.id, assessment?.id, page);
    // }

    const onAssesmentChange = (e) => {
        const val = (e.target && e.target.value) || '';
        setAssessment(val);
        getUsers(company?.id, pool?.id? pool?.id: team?.id, typeVal, email, industry?.id, startDate, endDate, targetProfile?.id, val?.id, page);
    }

    const onEmailChange = (e) => {
        let validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const val = (e.target && e.target.value) || '';
        setEmail(val);
        if (val && validEmail.test(val)) {
            getUsers(company?.id, pool?.id? pool?.id: team?.id, typeVal, val, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
        } else if (!val) {
            getUsers(company?.id, pool?.id? pool?.id: team?.id, typeVal, val, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page);
        }
    }

    const getCompanyPools = (id) => {
        setLoader(true);
        api.getCompanyPool(id? id:'')
            .then((res) => {
                res = res.data.data;
                setLoader(false)
                let poolList = [];
                for (let i = 0; i < res.length; i++) {
                    poolList.push({ id: res[i].hash, name: res[i].title });
                }
                poolList.unshift({id: null, name: 'All'})
                setPoolList(poolList);
            })
            .catch((error) => { })
    }

    const setPages = (val) => {
        setPageNo(val + page);
        if (!lastPage && users?.length) {
            getUsers(company?.id, pool?.id, typeVal, email, industry?.id, startDate, endDate, targetProfile?.id, assessment?.id, page + val);
        }
    }

    return (
        <>
            <div className="grid list-demo">
            <div className="grid">

            <div className="col">
                <div className="card overview-box softgreen">
                    <div className="overview-info">
                        <h6>Assessments Data</h6>
                        <h1>{stats?.assessments_done_count}</h1>
                    </div>
                    <i className="pi pi-users"></i>
                </div>
            </div>

            <div className="col">
                <div className="card overview-box mint-green">
                    <div className="overview-info">
                        <h6>Invites Accepted</h6>
                        <h1>{stats?.invites_accepted_count}</h1>
                    </div>
                    <i className="pi pi-globe"></i>
                </div>
            </div>

            <div className="col">
                <div className="card overview-box darkgray">
                    <div className="overview-info">
                        <h6>Invites Sent</h6>
                        <h1>{stats?.invites_sent_count}</h1>
                    </div>
                    <i className="pi pi-th-large"></i>
                </div>
            </div>


            </div>
                <div className="col-12">
                    <div className="card">
                        <div className="flex justify-content-between" style={{ marginBottom: '1rem' }}>
                            <div className="flex">
                                <h5 className="mr-2">Users</h5>
                                {loader ? <div> <i className="pi pi-spin pi-spinner" ></i></div> : ''}
                            </div>
                            <div>
                            <Calendar placeholder='Please select date range' className='w-3 mb-3 mr-2' selectionMode="range" value={dates} onChange={(e) => onDateChange(e)} showIcon readOnlyInput />
                                <InputText placeholder="Filter by email" className="mr-2" value={email || ''} onChange={(e) => onEmailChange(e)} />
                                <Dropdown options={companyList} filter optionLabel="name" value={company} onChange={(e) => onCompanyChange(e)} placeholder="Select Company" className="mr-2 mb-2" />
                                {company &&  <Dropdown options={typeOptions} optionLabel="name" value={typeVal} onChange={(e) => onTypeChange(e)} placeholder="Select Type" className="mr-2 mb-2" />}
                                {typeVal?.name === "Pool" &&  <Dropdown filter options={poolList} optionLabel="name" value={pool} onChange={(e) => onPoolChange(e)} placeholder="Select Pool" className="mr-2" />}
                                {typeVal?.name === "Team" &&  <Dropdown filter optionLabel="name" value={team} options={companyTeamList} onChange={(e) => onTeamChange(e)} placeholder="Select Team" className="mr-2 mb-2" />}
            
                                {/* <Dropdown options={poolList} optionLabel="name" value={pool} onChange={(e) => onPoolChange(e)} placeholder="Select Pool" className="mr-2" />
                                <Dropdown options={poolList} optionLabel="name" value={pool} onChange={(e) => onPoolChange(e)} placeholder="Select Pool" className="mr-2" /> */}
                                {/* <Dropdown options={industryList} optionLabel="name" value={industry} onChange={(e) => onIndustryChange(e)} placeholder="Select Industry" className="mr-2" />
                                <Dropdown options={targetProfileList} optionLabel="title" value={targetProfile} onChange={(e) => onTargetProfileChange(e)} placeholder="Select Target Profile" className="mr-2" /> */}
                                <Dropdown options={assessmentOpt} optionLabel="name" value={assessment} onChange={(e) => onAssesmentChange(e)} placeholder="Select Assessments" className="mr-2" />

                            </div>
                        </div>
                    </div>
                </div>
                <UsersList list={users} setPages={setPages} />
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Users, comparisonFn);