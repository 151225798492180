import React from 'react'; 
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import PoolDetails from './Pool-Details';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
export default function CompanyPoolsList({poolList, closeDialog, toast, pool, setPool, poolDialog, setPoolDialog}) {

    const rightToolbarTemplate = () => {
        
        return (
            <div className="my-2">
                <Button label="Add Pool" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => setProps(null)} />
            </div>
        )
    }
    const copyLink = (pool) => {
        navigator.clipboard.writeText(`https://talent.mazhr.com/?hash=${pool.hash} #register`);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: `Link has been copied`, life: 3000 });
    }
    const setProps = (pool) => {
        setPool(pool);
        setPoolDialog(true)
    }

    return (
        <div >
            <Dialog visible={poolDialog} style={{ width: '50%' }} header="Pool Detail" modal className="p-fluid" onHide={() => setPoolDialog(false)}>
            <PoolDetails pool={pool} close={closeDialog}></PoolDetails>
            </Dialog>
            <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
            {!poolList?.length ? <div className="card bg-lightGray flex justify-content-center ">No data found</div> :
                <div className="col-12 pool-mainDiv">
                    {poolList?.map((pool) => {
                        return (
                            <div className='mb-6' key={pool.hash}>
                                <div className="my-2">
                                    <Button label="Copy registration link" className="p-button-success mr-2" onClick={() => { copyLink(pool) }} />
                                </div>
                                <div className="card bg-lightGray flex pointer" onClick={() => { setProps(pool) }} >
                                    <span className="col-3" style={{ padding: '0' }}>
                                        <h5 >{pool.title}</h5>
                                        <small style={{ border: '1px solid', padding: '3px' }} >{moment(new Date(String(pool.deadline) )).format('MMM Do, YYYY')}</small>
                                    </span>

                                    <span className="col-4" style={{ padding: '0' }}>
                                        <p>{pool.description}</p>
                                    </span>

                                    <span className="col-5" style={{ padding: '0' }}>
                                        <p>{pool.industry.name}</p>
                                        <small>{pool.target_profile.name}</small>
                                    </span>
                                </div>
                            </div>
                        )
                    })}

                </div>
            }
        </div>
    )
}