import React, { useState } from 'react';
import TimeLine from './Timeline';
import KpiData from './KpiData';
import DashboardCorporares from './DashboardCorporates';



const Dashboard = () => {

    const [userRole] = useState(JSON.parse(localStorage.getItem('user_role')));



    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '8' }}>
                        <KpiData />
                    </div>
                    <div style={{ flex: '4' }}>
                        {userRole?.user_type_id !== 3 && <DashboardCorporares />}
                        <TimeLine />
                    </div>
                </div>

                {/* <div  className={`col-12 ${userRole?.user_type_id !== 3 ? 'md:col-6' : 'md:col-9 '}`}>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card device-status">
                                <div className="grid">
                                    <div className="col-12 xl:col-9">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h6>Corporates activity for last 4 days</h6>
                                                <p className="subtitle">Line graph</p>
                                            </div>
                                        </div>
                                        <p className="content">Labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud ercitation ullamco laboris nisi..</p>
                                        <div className="progress active">
                                            <span>Today</span>
                                            <ProgressBar className="progressBar" value={100} showValue={false}></ProgressBar>
                                            <span>2100</span>
                                        </div>
                                        <div className="progress">
                                            <span>Fri</span>
                                            <ProgressBar className="progressBar" value={20} showValue={false}></ProgressBar>
                                            <span>1100</span>
                                        </div>
                                        <div className="progress">
                                            <span>Thu</span>
                                            <ProgressBar className="progressBar" value={50} showValue={false}></ProgressBar>
                                            <span>1203</span>
                                        </div>
                                        <div className="progress">
                                            <span>Wed</span>
                                            <ProgressBar className="progressBar" value={100} showValue={false}></ProgressBar>
                                            <span>1316</span>
                                        </div>
                                        <button className="p-link">More</button>
                                    </div>
                                    <div className="col-12 xl:col-3">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h6>Devices</h6>
                                                <p className="subtitle">Type</p>
                                            </div>
                                        </div>
                                        <div className="grid grid-nogutter">
                                            <div className="col-3 xl:col-12">
                                                <div className="device">
                                                    <span><span>1</span> Corporate 1</span>
                                                    <span className="status">22%</span>
                                                </div>
                                            </div>
                                            <div className="col-3 xl:col-12">
                                                <div className="device">
                                                    <span><span>2</span> Corporate 2</span>
                                                    <span className="status">18%</span>
                                                </div>
                                            </div>
                                            <div className="col-3 xl:col-12">
                                                <div className="device">
                                                    <span><span>3</span> Corporate 3</span>
                                                    <span className="status">13%</span>
                                                </div>
                                            </div>
                                            <div className="col-3 xl:col-12">
                                                <div className="device">
                                                    <span><span>4</span> Corporate 4</span>
                                                    <span className="status">11%</span>
                                                </div>
                                            </div>
                                            <button className="p-link">See all</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12  desktop-teams">
                            <div className="card team">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Team</h6>
                                        <p className="subtitle">22 People</p>
                                    </div>
                                </div>
                                <div className="peoples">
                                    <img src="assets/demo/images/dashboard/avatar/avatar-1.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-2.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-3.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-4.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-5.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-6.png" alt="freya-layout" />
                                    <div className="no-picture" style={{ background: '#BAE6FF' }}><span>AT</span></div>
                                    <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                                    <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                                    <div className="no-picture "><span>+18</span></div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div > */}


                <div className="col-12 md:col-4 xl:col-3 mobile-teams">
                    <div className="card team">
                        <div className="card-header">
                            <div className="card-title">
                                <h6>Team</h6>
                                <p className="subtitle">22 People</p>
                            </div>
                        </div>
                        <div className="peoples">
                            <img src="assets/demo/images/dashboard/avatar/avatar-1.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-2.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-3.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-4.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-5.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-6.png" alt="freya-layout" />
                            <div className="no-picture" style={{ background: '#FFC3A2' }}><span>TA</span></div>
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-1.png" alt="freya-layout" />
                            <div className="no-picture" style={{ background: '#FFDB7D' }}><span>JH</span></div>
                            <img src="assets/demo/images/dashboard/avatar/avatar-2.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-3.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-4.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-5.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-6.png" alt="freya-layout" />
                            <div className="no-picture" style={{ background: '#BAE6FF' }}><span>SE</span></div>
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-1.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-2.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-3.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-4.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-5.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-6.png" alt="freya-layout" />
                            <div className="no-picture" style={{ background: '#91CC9D' }}><span>TH</span></div>
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <img src="assets/demo/images/dashboard/avatar/avatar-7.png" alt="freya-layout" />
                            <div className="no-picture"><span>+18</span></div>
                        </div>
                        <button className="p-link">See all teams</button>
                    </div>
                </div>

                {/* <div className="col-12 md:col-12 xl:col-8">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card chart">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Nasdaq 100</h6>
                                        <p className="subtitle">Line graph</p>
                                    </div>
                                </div>
                                <Chart type="line" data={chart2} options={chartOptions2} style={{ maxHeight: '330px' }}></Chart>
                            </div>
                        </div>
                        <div className="col-12 md:col-5">
                            <div className="card map">
                                <img src="assets/demo/images/dashboard/asset-map.png" alt="freya-layout" />
                                <div className="map-content">
                                    <h6>Last active region (data traffic)</h6>
                                    <div className="city">
                                        <span><span>1</span> Tokyo</span>
                                        <span className="status">22%</span>
                                    </div>
                                    <div className="city">
                                        <span><span>2</span> London</span>
                                        <span className="status">22%</span>
                                    </div>
                                    <button className="p-link">See all</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-7">
                            <div className="card schedule">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Schedule</h6>
                                        <p className="subtitle">Europe office</p>
                                    </div>
                                    <p className="subtitle">Today</p>
                                </div>
                                <p>3 events, 3h 30m</p>
                                <ul>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Daily talks over Zoom w/ teamA</h6>
                                            <span>8:00 - 9:00</span>
                                        </div>
                                        <button className="p-link">Join over Zoom</button>
                                        <span>6 participiants (4 accepted)</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>New Project Kick, v1</h6>
                                            <span>8:00 - 9:00</span>
                                        </div>
                                        <button className="p-link">Join over Zoom</button>
                                        <span>3 participiants (all accepted)</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>Grooming btw TeamA and SR1E</h6>
                                            <span>8:00 - 9:00</span>
                                        </div>
                                        <button className="p-link">Master room</button>
                                        <span>12 participiants (11 accepted)</span>
                                    </li>
                                    <li>
                                        <div className="schedule-header">
                                            <h6>New Project Kick, v2</h6>
                                            <span>12:00 - 14:00</span>
                                        </div>
                                        <button className="p-link">Master room</button>
                                        <span>5 participiants (1 accepted)</span>
                                    </li>
                                </ul>
                                <button className="p-link">Go to full schedule</button>
                            </div>
                        </div>
                    </div>
                </div > */}
                {/* <div className="col-12 xl:col-4">
                    <div className="grid">
                        <div className="col-12 md:col-6 xl:col-12">
                            <div className="card statistics">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>Top Corporate</h6>
                                    </div>
                                    <p className="subtitle">8 May</p>
                                </div>
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                <span>🐳</span>
                                                <h5>99,99%</h5>
                                            </div>
                                            <h6>Uptime/year</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                <span>🖥 </span>
                                                <h5>2K</h5>
                                            </div>
                                            <h6>Connected devices</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                <span>🎁 </span>
                                                <h5>244</h5>
                                            </div>
                                            <h6>Thanks messages received</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="statistic-item">
                                            <div className="item-title">
                                                <span>💵 </span>
                                                <h5>4812$</h5>
                                            </div>
                                            <h6>Earnings/month</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-12">
                            <div className="card stocks">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h6>👓 Stock watchlist (12)</h6>
                                        <p className="subtitle">Today</p>
                                    </div>
                                    <Button type="button" icon="pi pi-plus" className="p-button-secondary p-button-text p-button-rounded"></Button>
                                </div>
                                <ul>
                                    <li className="down">
                                        <div className="stock-name">
                                            <h6>AMD</h6>
                                        </div>
                                        <img src="assets/demo/images/dashboard/mini-graph-1.svg" alt="freya-layout" />
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-down"></i>
                                            <h6>54.20</h6>
                                        </div>
                                        <div className="stock-status">
                                            <span>0.57%</span>
                                            <span>4.01</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>MSFT</h6>
                                        </div>
                                        <img src="assets/demo/images/dashboard/mini-graph-2.svg" alt="freya-layout" />
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-down"></i>
                                            <h6>183.16</h6>
                                        </div>
                                        <div className="stock-status">
                                            <span>1.46%</span>
                                            <span>4.01</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>AAPL</h6>
                                        </div>
                                        <img src="assets/demo/images/dashboard/mini-graph-3.svg" alt="freya-layout" />
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-down"></i>
                                            <h6>307.20</h6>
                                        </div>
                                        <div className="stock-status">
                                            <span>0.59%</span>
                                            <span>4.01</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="stock-name">
                                            <h6>TSLA</h6>
                                        </div>
                                        <img src="assets/demo/images/dashboard/mini-graph-4.svg" alt="freya-layout" />
                                        <div className="stock-price">
                                            <i className="pi pi-arrow-down"></i>
                                            <h6>799.17</h6>
                                        </div>
                                        <div className="stock-status">
                                            <span>0.52%</span>
                                            <span>4.01</span>
                                        </div>
                                    </li>
                                    <li className="same">
                                        <div className="stock-name">
                                            <h6>AMZN</h6>
                                        </div>
                                        <img src="assets/demo/images/dashboard/mini-graph-5.svg" alt="freya-layout" />
                                        <div className="stock-price">
                                            <i className="pi pi-minus"></i>
                                            <h6>799.17</h6>
                                        </div>
                                        <div className="stock-status">
                                            <span>0.52%</span>
                                            <span>4.01</span>
                                        </div>
                                    </li>
                                </ul>
                                <button className="p-link">Show all</button>
                                <Button type="button" label="Go to full stock page"></Button>
                            </div>
                        </div>
                    </div>

                </div> */}
            </div>
        </div>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(Dashboard, comparisonFn);
