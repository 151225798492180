import React from 'react';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';



const UsersDetails = (props) => {

    
    return (
        <>
            {props?.userDetail.length === 0? 
             <>
            <div style={{display: "flex"}}>
                <ProgressSpinner/>
            </div>
            </>: 
            <>
                <div className="grid">
                <div className="col-12">
               {/* <h3 style={{textDecoration: "underline solid #00B0A0 5px"}}>User Details</h3>
                <br/> */}
                    <div >
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 lg:col-6">
                                <label htmlFor="full">Full Name</label>
                                <InputText value={props?.userDetail?.first_name + " " + props?.userDetail?.last_name} id="full" type="text" disabled/>
                                
                            </div>

                            <div className="field col-12 lg:col-6">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={props?.userDetail?.email} type="text" disabled/>                                
                            </div>

                            {/* <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="login">Last Login</label>
                                <InputText id="login" type="text" disabled />                                
                            </div> */}

                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="assessments">Assessments Completed</label>
                                <InputText id="assessments" value={props?.userDetail?.assessment_count} type="text" disabled />                                
                                
                            </div>

                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="corporation">Current Corporate</label>
                                <InputText id="corporation" value={props?.userDetail?.current_corporate} type="text"  disabled/>
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="linked">Linked Pools</label>
                                {props?.userDetail?.linked_pools.map((obj)=> {
                                    return <li key={props?.userDetail?.linked_pools.indexOf(obj)}>{obj}</li>
                                })}
                                {!props?.userDetail?.linked_pools.length && <p>No linked Teams Avaiable</p>}
                                {/* <InputText id="linked" type="text" disabled /> */}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="teams">Linked Teams</label>
                                {props?.userDetail?.linked_team.map((obj)=> {
                                    return <li key={props?.userDetail?.linked_team.indexOf(obj)}>{obj}</li>
                                })}
                                {!props?.userDetail?.linked_team.length && <p>No linked Teams Avaiable</p>}
                                {/* <InputText id="teams" type="text" disabled /> */}
                            </div>

                            <div className="field col-12 md:col-6 lg:col-4">
                                <label htmlFor="reports">Assessments Reports</label>
                                {props?.userDetail?.assessment_report.map((obj)=> {
                                    return <li key={obj.assessment_name}><a href={obj?.score_url} target="_blank" rel="noreferrer">{obj.assessment_name}</a></li>
                                })}
                                {!props?.userDetail?.assessment_report.length && <p>No Assessments Avaiable</p>}
                                {/* <InputText id="reports" type="text" disabled /> */}
                            </div>

                        </div>
            
                    </div>
                </div>
            </div>
            </>
           

            }
        </>);
}

export default UsersDetails;