import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import DataService from '../../service/AssistantToolApi-service'
import DiscountCodeList from './DiscountCodeList'
import AddDiscountCode from "./AddDiscountCode";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
const DiscountCode = () => {


    const [discountList, setDiscountList] = useState();
    const [loader, setLoader] = useState(false);
    const [addDialog, setAddDialog] = useState(false);
    const [modalData, setModalData] = useState({});
    const [code, setCode] = useState('');
    const toast = useRef(null);
    const [page, setPageNo] = useState(50);
    const [lastPage, setLastPage] = useState(false);
    const api = new DataService();

    useEffect(() => {
        setLoader(true);
        const getData = setTimeout(() => {
            getDiscountList()
        }, 1000);

        return () => clearTimeout(getData)
        //eslint-disable-next-line
    }, [code])

    const getDiscountList = () => {
        setLoader(true);
        api.getDiscountCodeList(code, page)
            .then((res) => {
                if (res.data.data.data) {
                    setDiscountList([...res.data.data.data]);
                    setLastPage(res.data.data.pagination.last_page);
                    setLoader(false);
                }
            }).catch((error) => {
                setLoader(false);
            });
    }




    const setPages = (val) => {
        setPageNo(val + page);
        if (!lastPage && discountList?.length) {
            getDiscountList();
        }
    }

    const hideDialog = (data) => {
        setAddDialog(false);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data, life: 3000 });
        getDiscountList();
    }

    const edit = (data) => {
        setModalData(data);
        setAddDialog(true)
    }

    const addCode = () => {
        setAddDialog(true);
        setModalData(null);
    }
    
    const showLoader = (event) => {
        setLoader(event)
    }



    return (
        <>
            <Dialog visible={addDialog} style={{ width: '45%' }} header="Discount Code" modal className="p-fluid" onHide={() => setAddDialog(false)}>
                <AddDiscountCode modalData={modalData} hideDialog={hideDialog}></AddDiscountCode>
            </Dialog>
            <div className="grid list-demo">
                <Toast ref={toast} />
                <div className="col-12">
                    <div className="card">
                        <div className="flex justify-content-between" style={{ marginBottom: '1rem' }}>
                            <div className="flex">
                                <h5 className="mr-2">Discount Codes</h5>
                                {loader && <div> <i className="pi pi-spin pi-spinner" ></i></div>}
                            </div>
                            <div>
                                <Button label="Add Discount Code" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => addCode()} />
                                <InputText placeholder="Search..." className="mr-2" value={code || ''} onChange={(e) => setCode(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <DiscountCodeList list={discountList} setPages={setPages} edit={edit} delete={getDiscountList} showLoader={showLoader} />
            </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DiscountCode, comparisonFn);