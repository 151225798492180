import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import AssitantToolApi from '../../service/AssistantToolApi-service';
import UsersDetails from './UsersDetails';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ColumnGroup } from 'primereact/columngroup';
import { Badge } from 'primereact/badge';
import { ctx } from '../../AppWrapper'
import { Row } from 'primereact/row';
const ReportListView = (props) => {
    const apiObject = new AssitantToolApi();
    const [userDetail, setUserDetail] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [column, setcolumns] = useState([]);
    const [sorted, setSorted] = useState(false);
    const ctxState = useContext(ctx);
    useEffect(() => {
        setReportList(props.list);
        setcolumns(props.column);
        calculateAvergae(props)
    }, [props]);// eslint-disable-line react-hooks/exhaustive-deps

    const calculateAvergae = (props) => {
        let avgArray = []
        if (props.column.length && props.list.length) {
            props.column.forEach((col) => {
                const sum = props.list.map((item) => item[`${col.column_name}`])
                    .reduce((total, value) => total + value, 0);
                const average = (sum / props.list.length).toFixed(1);
                let clor = '';
                ctxState?.pointColors?.colors_data_for_9?.forEach((val) => {
                    if (val.points_list.includes(Math.round(average))) {
                        clor = val.color;
                    }
                });
                avgArray.push({ ...col, avg: average, clr: clor });
            });
            setcolumns(avgArray)
        }
    }

    const statusBodyTemplate = (rowData, column) => {
        const field = column.field;
        let clr = '';
        ctxState.pointColors?.colors_data_for_9?.forEach((val) => {
            if (val.points_list.includes(rowData[`${field}`])) {
                clr = val.color;
            }
        });
        return <Badge value={rowData[`${field}`]} size="large" severity="success" style={{ background: `${clr}`, color: 'white' }}  ></Badge>
    }

    const footerTemplate = (data) => {
        return <Badge value={data.avg} size="xlarge" severity="success" style={{ background: 'white', color: `${data.clr}` }}  ></Badge>
    }

    const sortColumn = (val) => {
        setSorted(val);
        if (val) {
            column.sort((a, b) => b.avg - a.avg);
        } else {
            column.sort((a, b) => a.avg - b.avg);
        }

    }

    const getUserData = (userId) => {
    apiObject.getUserDetail(userId).then(
        (res) => {
            setUserDetail(res.data.data)

    })

    }

    const handleClick = (e) => {
        setShowDialog(true);
        setUserDetail([])
        getUserData(e.data?.user_id)
    }

    const averageHeading = () => {
        return (
            <>
                <span style={{ cursor: 'pointer' }} onClick={() => sortColumn(!sorted)}>
                    <i className="pi pi-sort-alt rotate-90" /> &nbsp;
                    Average :
                </span>
            </>
        )
    }

    let footerGroup = column.length && <ColumnGroup>
        <Row>
            <Column colSpan={2} footer={averageHeading} style={{ width: '320px', textAlign: 'center' }} />
            {column && column.map((column, index) => {
                return <Column key={index} footer={footerTemplate(column)} style={{ width: '160px', textAlign: 'center', background: `${column.clr}`, borderColor: 'white' }} />
            })}
        </Row>
    </ColumnGroup>;

    return (
        <>
            <div className="col-12">
                <div className="card">
                    <Dialog header="User Details" style={{ width: '45%' }} visible={showDialog} onHide={() => setShowDialog(false)}>
                        <UsersDetails userDetail={userDetail}/>
                    </Dialog>
                    <DataTable style={{cursor: "pointer"}} onRowClick={(e) => handleClick(e)} ref={props.forwardedRef} value={reportList} scrollable showGridlines responsiveLayout="scroll" scrollHeight="500px" scrollDirection="both"
                        footerColumnGroup={footerGroup} className="mt-3">
                        <Column field="first_name" sortable header="First Name" style={{ flexGrow: 1, flexBasis: '160px', justifyContent: 'center' }} frozen></Column>
                        <Column field="last_name" sortable header="Last Name" style={{ flexGrow: 1, flexBasis: '160px', justifyContent: 'center' }} alignFrozen="left" ></Column>
                        {column && column.map((column, index) => {
                            return <Column key={index} field={column.column_name} header={column.header_name} sortable style={{ flexGrow: 1, flexBasis: '160px', justifyContent: 'center' }} body={statusBodyTemplate} ></Column>
                        })}
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default ReportListView;