import React from 'react'; 
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import TeamDetails from './Team-Details';
import { Dialog } from 'primereact/dialog';
export default function CompanyTeamsList({teamList, closeDialog, toast, team, setTeam, teamDialog, setTeamDialog}) {


    const rightToolbarTemplate = () => {
        
        return (
            <div className="my-2">
                <Button label="Add Team" icon="pi pi-plus" className="p-button-success mr-2" onClick={() => setProps(null)} />
            </div>
        )
    }
    const setProps = (team) => {
        setTeamDialog(true)
        setTeam(team)
    }

    return (
        <div >
            <Dialog visible={teamDialog} style={{ width: '50%' }} header="Team Detail" modal className="p-fluid" onHide={() => setTeamDialog(false)}>
            <TeamDetails team={team} close={closeDialog}></TeamDetails>
            </Dialog>
            <Toolbar className="mb-4" right={rightToolbarTemplate} ></Toolbar>
            {!teamList?.length ? <div className="card bg-lightGray flex justify-content-center ">No data found</div> :
                <div className="col-12 pool-mainDiv">
                    {teamList?.map((team) => {
                        return (
                            <div className='mb-6' key={team.hash}>
                                <div className="card bg-lightGray flex pointer" onClick={() => { setProps(team) }}>
                                    <span className="col-3" style={{ padding: '0' }}>
                                        <h5 >{team.title}</h5>
                                    </span>

                                    <span className="col-4" style={{ padding: '0' }}>
                                        <p>{team.description}</p>
                                    </span>

                                    <span className="col-5" style={{ padding: '0' }}>
                                        <p>{team?.industry?.name}</p>
                                    </span>
                                </div>
                            </div>
                        )
                    })}

                </div>
            }
        </div>
    )
}