import React, { useState, useEffect, useContext, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import DataService from '../../service/AssistantToolApi-service';
import { classNames } from 'primereact/utils';
import { companyContext } from './Corporaters';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import UpdateCredentials from './Update-Credentials';
import { Toast } from 'primereact/toast';
import './Corporates.scss'
function CorporateDetails (props) {
    let emptyCorporaters = {
        contact_phone: "",
        name: "",
        business_type: "",
        employee_count: "",
    };
    const [corporaters, setCorporaters] = useState(emptyCorporaters);
    const company = useContext(companyContext);
    const [industryList] = useState(company.industryList);
    const [corporateDialog, setCorporateDialog] = useState(false);
    const [corporateEmail, setCorporateEmail] = useState('');
    const api = new DataService();
    const toast = useRef(null);
    useEffect(() => {
        if (props?.company) {
            setCorporaters(props?.company);
        }
    }, [props]);

    const changeHandler = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _corporaters = { ...corporaters };
        _corporaters[`${name}`] = val;
        setCorporaters(_corporaters);
    }
    const editDetail = () => {

        let payLoad = {};
        payLoad.company_name = corporaters.name;
        payLoad.contact_phone = corporaters.contact_phone;
        payLoad.employee_count = corporaters.employee_count;
        payLoad.logo = ""
        payLoad.industry_id = corporaters.industry.id + "";
        api.editCompanyInfo(payLoad, corporaters.id)
            .then((res) => {
                api.addEvent(2);
            })
            .catch((error) => { })
    }

    const openUpdateCredential = () => {
        toast.current.show({ severity: 'info', summary: 'Info', detail: 'Please wait', life: 1500 });
        api.getCompanyEmail(company.id)
            .then((res) => {
                res = res.data.data;
                setCorporateEmail(res.email)
                setCorporateDialog(true);
            })
            .catch((err) => {
                setCorporateDialog(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: `Something went wrong`, life: 3000 });
            });
    }

    const closeCredentials = (e) => {
        setCorporateDialog(false);
        if (e) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: `Credentials Update Successfully`, life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Something went wrong`, life: 3000 });
        }

    }

    return (
        <>
            <div className="grid">
                <Toast ref={toast} />
                <div className="col-12">
                    <div >
                        <div className="flex justify-content-between m-2">
                            <h5> Corporate Details</h5>

                            <span>
                                <Button label="Update Credentials" className="p-button-success mr-2" onClick={() => openUpdateCredential()} />
                                <Button label="Save" className="p-button-success mr-2" onClick={editDetail} />
                            </span>
                        </div>
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="company_naem">Companny Name<span className={"mandatory-field"}>*</span></label>
                                <InputText id="company_naem" type="text" value={corporaters.name} onChange={(e) => changeHandler(e, 'name')} />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="business_type">Buisness Type<span className={"mandatory-field"}>*</span></label>
                                <Dropdown optionLabel="name" options={industryList} placeholder="Select type" value={corporaters.industry} onChange={(e) => changeHandler(e, 'industry')} required className={classNames({ 'p-invalid': !corporaters.industry })} />
                                {/* <InputText id="business_type" type="text" value={corporaters.business_type} onChange={(e) => changeHandler(e, 'business_type')} /> */}
                            </div>
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="employee_count">Total Employee<span className={"mandatory-field"}>*</span></label>
                                <InputText id="business_type" type="text" value={corporaters.employee_count} onChange={(e) => changeHandler(e, 'employee_count')} />
                            </div>
                            <div className="field col-12 md:col-6 lg:col-6">
                                <label htmlFor="contact_phone">Contact Number<span className={"mandatory-field"}>*</span></label>
                                <InputText id="contact_phone" value={corporaters?.contact_phone || ''} onChange={(e) => changeHandler(e, 'contact_phone')} />
                            </div>
                        </div>

                        <Dialog visible={corporateDialog} style={{ width: '45%' }} header="Update Credentials" modal className="p-fluid" onHide={() => setCorporateDialog(false)}>
                            <UpdateCredentials corporateEmail={corporateEmail} closeCredentials={closeCredentials} />

                        </Dialog>

                    </div>
                </div>
            </div>
        </>
    );
}

export default CorporateDetails;
